import styles from "./Medals.module.scss"
import JsonData from "../../../data/JsonData";
import PortalModal from "../../ui/modals/PortalModal";
import ModalFull from "../../ui/modals/ModalFull";
import ActiveMedal from "../../partsLarge/Medals/ActiveMedal";
import InactiveMedal from "../../partsLarge/Medals/InactiveMedal";
import TitleAndCopySet from "../../partsSmall/titles/TitleAndCopySet";
import Button from "../../ui/buttons/Button";
import {useRef} from "react";
import {useLocalStorageContext} from "../../../contexts/LocalStorageContext";
import {Sanitize} from "../../../utils/common";
import Audio from "../../../audios/Audio";
import {useModalContext} from "../../../contexts/ModalContext";

function Medals({open = false, onClose = null}) {
  /** テキストデータ **/
  const TextData = JsonData.textData

  /** LocalStorage **/
  const {generalMedal} = useLocalStorageContext()

  /** Contexts **/
  const {
    showMedalDetailModal
  } = useModalContext()

  /**
   * 詳細モーダル表示
   */
  const detailOpen = (e) => {
    Audio.playSE('button')
    showMedalDetailModal(e.currentTarget.dataset.id)
  }

  /**  **/
  const childRef = useRef()
  const hide = () => {
    childRef.current.hide()
  }

  return (<>
    <PortalModal open={open}>
      <ModalFull ref={childRef} open={open} onClose={onClose}>
        <div className={styles.wrapper}>
          <TitleAndCopySet title={TextData.medal.title}>
            <p dangerouslySetInnerHTML={{__html: Sanitize(TextData.medal.text)}} />
          </TitleAndCopySet>

          <div className={styles.list}>
            <div className={styles.listInner}>
              {JsonData.medalData.map(data => {
                const getMedal = generalMedal?.medals?.includes(data.id)
                if(getMedal) {
                  return <ActiveMedal key={data.id} data={data} detailOpen={detailOpen} />
                } else {
                  return <InactiveMedal key={data.id} data={data} />
                }
              })}

              {/*flex で、最終行が左寄せになるように空divを*/}
              <div className={styles.empty} />
              <div className={styles.empty} />
              <div className={styles.empty} />
              <div className={styles.empty} />
              <div className={styles.empty} />
              <div className={styles.empty} />
              <div className={styles.empty} />
              <div className={styles.empty} />
              <div className={styles.empty} />
            </div>
          </div>
          <div className={styles.buttonWrap}>
            <Button onClick={hide} color="brown" seName="menuClose">{TextData.common.close}</Button>
          </div>
        </div>
      </ModalFull>
    </PortalModal>
  </>)
}

export default Medals
