import styles from './StoryDebug.module.scss'
import {useLocalStorageContext} from "../../../contexts/LocalStorageContext";
import JsonData from "../../../data/JsonData";

function StoryDebug({storyId}) {
  const StoryData = JsonData.storyListData.filter(d => d.id === storyId)[0]
  const medals = StoryData.medals.split(",")

  /** 使用するローカルストレージの値 **/
  const {
    userStoryData,
    editUserStoryMedalData,
  } = useLocalStorageContext()

  const toggleMedal = (medalId, active = true) => {
    if(active) {
      editUserStoryMedalData('remove', medalId)
    } else {
      editUserStoryMedalData('add', medalId)
    }
    window.location.reload()
  }

  return (userStoryData) ?
    <div className={styles.wrapper}>
      {medals.map(medalId => {
        if (userStoryData.medals && userStoryData.medals.includes(medalId)) {
          return <button onClick={() => {
            toggleMedal(medalId)
          }} key={medalId} className={styles.medal}>{medalId}</button>
        } else {
          return <button onClick={() => {
            toggleMedal(medalId, false)
          }} key={medalId} className={`${styles.medal} ${styles.inactive}`}>{medalId}</button>
        }
      })}
    </div>:
    null
}

export default StoryDebug
