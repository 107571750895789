import styles from "./TitleAndCopySet.module.scss";
import TitleWave from "../../ui/titles/TitleWave";
import {Sanitize} from "../../../utils/common";

function TitleAndCopySet({title, copy, children}) {
  return(<div className={styles.wrapper}>
    {title && <TitleWave>{title}</TitleWave>}
    {copy && <h3 className={styles.catch} dangerouslySetInnerHTML={{__html: Sanitize(copy)}}/>}
    {children && <div className={styles.children}>
      {children}
    </div>}
  </div>)
}
export default TitleAndCopySet
