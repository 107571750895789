import React from "react";
import styles from "./MedailIdAndTitle.module.scss";

function MedalIdAndTitle({medalId, title}) {
  return (<div className={styles.wrapper}>
    <p className={styles.id}>{medalId}</p>
    <p className={styles.title}>{title}</p>
  </div>)
}

export default MedalIdAndTitle
