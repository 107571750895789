//https://reffect.co.jp/react/react-usecontext-understanding
//https://dev.to/zettadam/how-to-handle-multiple-modals-in-a-react-application-2pei

import {createContext, useContext, useState} from 'react';
import ModalManager, {ModalStates} from "../managers/ModalManager";

const ModalContext = createContext()

export function useModalContext() {
  return useContext(ModalContext)
}

const ModalProvider = ({children, defaultSelectedModal = ''}) => {
  const [selectedModal, setSelectedModal] = useState(defaultSelectedModal)
  const [medalDetailId, setMedalDetailId] = useState(null)

  const showAboutModal = () => {
    setSelectedModal(ModalStates.about)
  }
  const showHowToModal = () => {
    setSelectedModal(ModalStates.howTo)
  }
  const showMedalModal = () => {
    setSelectedModal(ModalStates.medal)
  }
  const showMedalDetailModal = (medalId) => {
    setMedalDetailId(medalId)

    // すでに”メダル一覧”を開いている場合は、medalOnMedalDetailとしておく
    // メダル詳細を閉じる際に"メダル一覧"へ戻すため
    if(selectedModal === ModalStates.medal) {
      setSelectedModal(ModalStates.medalOnMedalDetail)
    } else {
      setSelectedModal(ModalStates.medalDetail)
    }

  }
  const closeModal = (modalState = "") => {
    setSelectedModal(modalState)
  }

  const value = {
    selectedModal,
    setSelectedModal,
    showAboutModal,
    showHowToModal,
    showMedalModal,
    showMedalDetailModal,
    closeModal
  }

  return (<ModalContext.Provider value={value}>
    {children}
    <ModalManager medalDetailId={medalDetailId} />
  </ModalContext.Provider>);
};

export default ModalProvider;
