import styles from "./CloseButton.module.scss"
import closeBtnImg from "../../../img/common/icon_close.svg"

function CloseButton({onClose}) {

  return (<button className={styles.wrapper} onClick={onClose}>
    <img className={styles.iconImg} src={closeBtnImg} alt="とじる"/>
  </button>)

}

export default CloseButton
