import {useState, useEffect} from 'react';
import {GetStorageValue} from "../utils/common";

// https://blog.logrocket.com/using-localstorage-react-hooks/

export const useLocalStorage = (key) => {
  const [value, setValue] = useState(() => {
    return GetStorageValue(key)
  })

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}
