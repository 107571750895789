import {useLocalStorage} from "../hooks/useLocalStorage";
import {LocalStorageKeys} from "../constants/AppConstant";

/**
 * メダル一覧の取得状況をローカルストレージに保存
 *
 * ▼▼▼ この値の役割 ▼▼▼
 * ・メダル一覧の取得状況
 *
 * ▼▼▼ 削除のタイミング ▼▼▼
 * こちらでは削除はしない。ユーザーが削除した場合のみ
 *
 * @constructor
 */
export const GeneralMedalsLocalStorage = () => {
  const [generalMedal, setGeneralMedal] = useLocalStorage(LocalStorageKeys.GeneralMedals)

  /**
   * {LocalStorageKeys.GeneralMedals}を更新
   * @param medals {Array}
   */
  const update = (medals) => {
    setGeneralMedal({
      date: new Date().getTime(),
      medals
    })
  }

  /**
   * {LocalStorageKeys.GeneralMedals}の`medals`にmedalIdを追加
   * @param medalId {Number}
   */
  const addGeneralMedal = (medalId) => {
    let medals = generalMedal.medals
    if(!medals) medals = []
    if(!medals.includes(medalId)) medals.push(medalId)
    update(medals)
  }

  return {generalMedal, addGeneralMedal}
}
