import {useEffect, useRef} from 'react';
import Button from "../../ui/buttons/Button";
import gsap from "gsap";
import DebugConstants from "../../../constants/DebugConstants";

/** Debug機能 **/
const killButtonFadeIn = DebugConstants.getValues('Story', 'killButtonFadeIn')

function StoryNextButton({onNext, text}) {

  /** refs **/
  const buttonEl = useRef()

  useEffect(() => {
    if(buttonEl.current) buttonEl.current.style.pointerEvents = 'none'

    const buttonFadeInFunction = () => {
      const delay = killButtonFadeIn ? 0 : 1
      if(buttonEl.current) {
        return gsap.fromTo(buttonEl.current, {
          opacity: 0
        }, {
          opacity: 1,
          duration: 0.6,
          delay,
          clearProps: "all",
          onComplete: () => {
            if(buttonEl.current) buttonEl.current.style.pointerEvents = 'visible'
          }
        })
      } else {
        return null
      }
    }
    const gsapObj = buttonFadeInFunction()
    return () => {
      if(gsapObj) gsapObj.kill()
    }
  }, [buttonEl])

  return <Button _ref={buttonEl} onClick={onNext}>{text}</Button>
}

export default StoryNextButton
