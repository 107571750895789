import styles from "./StoryTop.module.scss"
import {motion} from "framer-motion"
import JsonData from "../../../data/JsonData";
import {useNavigate, useParams} from "react-router-dom";
import ContinueModal from "../../partsLarge/Top/ContinueModal";
import Button from "../../ui/buttons/Button";
import {useEffect, useState} from "react";
import StoryMenu from "../../partsLarge/Common/StoryMenu";
import {useLocalStorageContext} from "../../../contexts/LocalStorageContext";
import InitHowTo from "../HowTo/InitHowTo";
import Noise from "../../partsSmall/common/Noise";
import {useBudouX} from "../../../hooks/useBudouX";
import {Sanitize} from "../../../utils/common";
import ModalProvider from "../../../contexts/ModalContext";
import lottie from "lottie-web";

function StoryTop() {

  const { parse } = useBudouX()
  /** Routers **/
  const navigate = useNavigate();
  // storyId(momotaro・pigs・antgrass)をURLから取得
  const {storyId} = useParams()

  /** Values **/
  // 三匹のこぶたのときだけ、文字が白色
  const whiteTitle = storyId === "pigs"

  /** JSONデータ **/
  // テキストデータ
  const TextData = JsonData.textData
  // ストーリー情報
  const storyListData = JsonData.storyListData
  // storyIdからストーリーの情報を取得
  const storyData = storyListData.filter(d => d.id === storyId)[0]
  // ストーリーの最初のチャプターIDを習得
  const firstChapterId = JsonData.storiesData[storyId][0].id

  /** LocalStorage **/
  const {userStoryData, recordUserStoryData} = useLocalStorageContext()

  /** States **/
    // 「つづきからよみますか？」モーダルの表示
  const [showContinueModal, setShowContinueModal] = useState(!!(userStoryData && userStoryData.storyId && userStoryData.sceneId))
  // ページ遷移トランジションをするかしないか
  const [pageTransition, setPageTransition] = useState(false)

  /************************
   * Functions
   ************************/

  /**
   * （はじまりまじまり）と共に、ローカルストレージのUserLocalStorageに初期値を記録
   * そして物語の1ページ目へ遷移 ※Story.jsのURL直打ち禁止処理のせいで進めないため、setTimeoutしている
   */
  const onClick = () => {
    setPageTransition(true)
    recordUserStoryData(storyId, firstChapterId)
    setTimeout(() => {
      navigate(`/s/${storyId}/${firstChapterId}`)
    }, 0)
  }

  /**
   * コンテニューモーダルで「最初から始める」を選んだ場合
   */
  const onDefuse = () => {
    setShowContinueModal(false)
  }


  /**
   * トップのアニメーションのLottieを作成
   */
  const createLottie = () => {
    return lottie.loadAnimation({
      container: document.getElementById('lottie'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: `/assets/pictures/${storyId}/${storyId}_start-main.json`,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    })
  }

  useEffect(() => {
    const lottieObj = createLottie()
    return () => {
      if(lottieObj) lottieObj.destroy()
    }
  }, [])

  return (<ModalProvider>
      <motion.div
        className={styles.pageTransition}
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={pageTransition ? {translateX: "-100px", width: 0} : {}}
        transition={{duration: 0.5}}
      >
        <div className={styles.wrapper}>
          <div className={styles.bg}>
            <img src={`/assets/pictures/${storyId}/${storyId}_start-bg.svg`} alt=""/>
          </div>
          <div className={styles.centering}>
            <div className={styles.main}>
              <div className={`${styles.title} ${whiteTitle ? styles.whiteTitle : ''}`}>
                <p dangerouslySetInnerHTML={{__html: Sanitize(`${parse(TextData.story.title)}<br/>${parse(storyData.title)}`) }} />
              </div>
              <div id="lottie" className={styles.visual}>
                {/*<img src={`/assets/pictures/${storyId}/${storyId}_start-main.svg`} alt=""/>*/}
              </div>
            </div>
          </div>
          <Noise/>
          <div className={styles.buttonWrap}>
            <Button onClick={onClick} seName="nextPage">{TextData.story.start}</Button>
          </div>
        </div>

        {/* 物語の途中で離脱している場合の、続きから読むモーダル */}
        <ContinueModal open={showContinueModal} goToNext={onDefuse}/>

        {/* 初回のあそびかたモーダル */}
        <InitHowTo/>
      </motion.div>

      {/* ストーリー内のメニュー */}
      <StoryMenu/>
    </ModalProvider>
  )
}

export default StoryTop
