import storiesData from './json/storiesData.json'
import medalData from './json/medalData.json'
import imageFileListData from './json/fileList.json'
import textData from './json/textData.json'
import storyListData from './json/storyListData.json'

let instance;

class JsonData {
  constructor() {
    if (instance) {
      throw new Error("You can only create one instance!");
    }
    instance = this;

    this._storiesData = storiesData
    this._medalData = medalData
    this._imageFileListData = imageFileListData
    this._textData = textData
    this._storyListData = storyListData
  }

  get storiesData() {
    return this._storiesData
  }

  get medalData() {
    return this._medalData
  }

  get imageFileListData() {
    return this._imageFileListData
  }

  get textData() {
    return this._textData
  }

  get storyListData() {
    return this._storyListData
  }
}

export default new JsonData()
