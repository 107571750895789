import styles from "./ContinueModal.module.scss"
import Modal from "../../ui/modals/Modal";
import Button from "../../ui/buttons/Button";
import JsonData from "../../../data/JsonData";
import {useLocalStorageContext} from "../../../contexts/LocalStorageContext";
import PortalModal from "../../ui/modals/PortalModal";

function ContinueModal({open, onClose}) {
  const TextData = JsonData.textData
  // LocalStorage
  const {userStoryData, editUserStoryMedalData} = useLocalStorageContext()

  const onDefuse = () => {
    editUserStoryMedalData("delete")
    if (onClose) onClose()
  }

  return (userStoryData && <PortalModal open={open}>
    <Modal open={open}>
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <p className={styles.title}>{TextData.continue.title}</p>
          <ul className={styles.buttons}>
            <li><Button href={`/s/${userStoryData.storyId}/${userStoryData.sceneId}/`}
                    seName="button">{TextData.continue.continue}</Button></li>
            <li><Button onClick={onDefuse} seName="button">{TextData.continue.restart}</Button></li>
          </ul>
        </div>
      </div>
    </Modal>
  </PortalModal>)
}

export default ContinueModal
