import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import styles from "./ModalFull.module.scss"
import ModalBase from "./ModalBase";
import CloseButton from "../buttons/CloseButton";
import ScrollableContainer from "../../partsLarge/Common/ScrollableContainer";
import {ModalZIndex} from "../../../constants/AppConstant";
import Audio from "../../../audios/Audio";

function ModalFull(
  {
    // 表示フラグ
    open,
    // クローズ関数
    onClose,
    // zIndexの設定が必要な場合の関数
    zIndex = null,
    //
    children
  }, ref) {

  const childRef = useRef()
  const hide = () => {
    childRef.current.hide()
  }
  useImperativeHandle(ref, () => ({
    hide
  }))

  return (<ModalBase ref={childRef} open={open} onClose={onClose} zIndex={zIndex ? zIndex : ModalZIndex.compact}>
    <div className={styles.wrapper}>
      <ScrollableContainer>
        <div className={styles.content}>
          {children}
        </div>
      </ScrollableContainer>
      {onClose && <div className={styles.closeButton}>
        <CloseButton onClose={() => {
          Audio.playSE('menuClose')
          hide()
        }}/>
      </div>}
    </div>
  </ModalBase>)
}

export default forwardRef(ModalFull)
