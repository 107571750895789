import styles from "./StoryNormal.module.scss"
import StoryPictures from "./StoryPictures";
import {useBudouX} from "../../../hooks/useBudouX";
import {Sanitize} from "../../../utils/common";

function StoryNormal({text, storyId, sceneId}) {
  const { parse } = useBudouX()

  const htmlString = parse(text)

  return (<main className={styles.wrapper}>
    <div className={styles.grid}>
      <div className={styles.textWrap}>
        <div className={styles.textInner}>
          {(text) && <p className={styles.text} dangerouslySetInnerHTML={{__html: Sanitize(htmlString)}}/>}
        </div>
      </div>
      <div className={styles.storyPicture}>
        <StoryPictures storyId={storyId} sceneId={sceneId}/>
      </div>
    </div>
  </main>)
}

export default StoryNormal
