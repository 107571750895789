import styles from "./Image.module.scss";
import {useEffect, useRef, useState} from "react";
import lottie from "lottie-web";

function LottieImage({positions, id, lottieSrc}) {

  // refs
  const wrapperRef = useRef()

  // states
  const [size, setSize] = useState(0)

  // styles
  const style = GetPositions(positions)

  useEffect(() => {
    // Lottie
    const lottieObj = CreateLottie(id, lottieSrc)
    return () => {
      if(lottieObj) lottieObj.destroy()
    }
  }, [])

  useEffect(() => {
    adjustSVGSize()
    addEvent()
    return () => {
      removeEvent()
    }
  }, [])

  const addEvent = () => {
    if(typeof window !== undefined) {
      window.addEventListener('resize', resizeEvent)
    }
  }

  const removeEvent = () => {
    if(typeof window !== undefined) {
      window.removeEventListener('resize', resizeEvent)
    }
  }

  let timerId = null
  const resizeEvent = () => {
    clearTimeout(timerId)
    timerId = setTimeout(adjustSVGSize, 100)
  }

  const adjustSVGSize = () => {
    const rect = wrapperRef.current.getBoundingClientRect()
    const min = Math.min(rect.width, rect.height)
    setSize(min)
  }

  return <div ref={wrapperRef} className={styles.lottie}>
    <div
      id={id}
      style={{...style, ...{width: `${size}px`, height:`${size}px`}}}
    />
  </div>
}

const CreateLottie = (elementId, fileSrc) => {
  return lottie.loadAnimation({
    container: document.getElementById(elementId), // the dom element that will contain the animation
    renderer: 'svg',
    loop: true,
    autoplay: true,
    path: fileSrc
  })
}

const GetPositions = (positions) => {
  let style = {}
  if(positions) {
    switch (positions.x) {
      case "right":
        style.right = 0
        break
      case "left":
        style.left = 0
        break
      case "center":
        style.left = "50%"
        style.transform = "translateX(-50%)"
        break
      default:
        style.left = positions.x
        break
    }
    if(positions.isUnder) {
      style.bottom = 0
    } else {
      switch (positions.y) {
        case "top":
          style.top = 0
          break
        case "bottom":
          style.bottom = 0
          break
        case "center":
          style.top = "50%"
          style.transform = "translateY(-50%)"
          break
        default:
          style.top = positions.y
          break
      }
    }
    if(positions.y === "center" && positions.x === "center") {
      style.transform = "translate(-50%, -50%)"
    }
  }

  if(positions.transform) {
    style.transform = positions.transform
  }
  return style
}

export default LottieImage
