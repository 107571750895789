import {Link} from "react-router-dom";
import styles from "./Button.module.scss"
import Audio from "../../../audios/Audio";

const Size = {
  "middle": styles.middle
}

const Color = {
  "blue": styles.colorBlue,
  "brown": styles.colorBrown
}

function Button(
  {
    // href
    href = '',
    // functions
    onClick = null,
    // styles
    size = '',
    color = 'blue',
    icon = '',
    // se
    seName = '',
    // refを渡す
    _ref = null,
    // children
    children
  }) {

  const sizeStyle = (!!size) ? Size[size] : ''
  const colorStyle = Color[color]
  const className = `button ${styles.button} ${sizeStyle} ${colorStyle}`

  const isExternal = href.startsWith('http')

  const playSE = () => {
    if(seName) Audio.playSE(seName)
  }

  const content = () => {
    return <>
      <p>{children}</p>
      {icon === "external" && <div className={styles.externalIcon} />}
    </>
  }

  if(!!href) {
    if(isExternal) {
      return <a
        ref={_ref && _ref}
        href={href}
        target="_blank"
        rel="noreferrer"
        className={className}
        onClick={playSE}
      >
        {content()}
      </a>
    } else {
      return(<Link
        ref={_ref && _ref}
        to={href}
        className={className}
        onClick={playSE}
      >
        {content()}
      </Link>)
    }

  } else {
    return(<button
      ref={_ref && _ref}
      className={className}
      onClick={(e) => {
        if(onClick) {
          e.preventDefault()
          playSE()
          onClick()
        }
      }}
    >
      {content()}
    </button>)
  }


}

export default Button
