//https://reffect.co.jp/react/react-usecontext-understanding
//https://dev.to/zettadam/how-to-handle-multiple-modals-in-a-react-application-2pei

import {createContext, useContext} from 'react';
import {GeneralMedalsLocalStorage} from "../storages/GeneralMedalsLocalStorage";
import {UserStoryDataLocalStorage} from "../storages/UserStoryDataLocalStorage";

const LocalStorageContext = createContext()

export function useLocalStorageContext() {
  return useContext(LocalStorageContext)
}

const LocalStorageProvider = ({children, defaultSelectedModal = ''}) => {
  const {generalMedal, addGeneralMedal} = GeneralMedalsLocalStorage()
  const {userStoryData, editUserStoryMedalData, recordUserStoryData, updateUserStoryPrevData} = UserStoryDataLocalStorage()

  const value = {
    generalMedal, addGeneralMedal,
    userStoryData, editUserStoryMedalData, recordUserStoryData, updateUserStoryPrevData
  }

  return (<LocalStorageContext.Provider value={value}>
    {children}
  </LocalStorageContext.Provider>);
};

export default LocalStorageProvider;
