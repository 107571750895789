import styles from "./Modal.module.scss"
import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import {useEffect} from "react";
import gsap from "gsap";
import CloseButton from "../buttons/CloseButton";
import ModalBase from "./ModalBase";
import ScrollableContainer from "../../partsLarge/Common/ScrollableContainer";
import {ModalZIndex} from "../../../constants/AppConstant";
import Audio from "../../../audios/Audio";

function Modal(
  {
    // 表示フラグ
    open,
    // クローズ関数
    onClose = null,
    hideClose = false,
    // zIndexの設定が必要な場合の関数
    zIndex = null,
    //
    children
  }, ref) {
  // Element Refs
  const wrapperRef = React.createRef()

  useEffect(() => {
    if(open) {
      gsap.to(wrapperRef.current, {
        opacity: 1
      })
      wrapperRef.current.style.pointerEvents = 'visible'
    } else {
      gsap.to(wrapperRef.current, {
        opacity: 0
      })
      wrapperRef.current.style.pointerEvents = 'none'
    }
  }, [open, wrapperRef])


  const childRef = useRef()
  const hide = () => {
    childRef.current.hide()
  }
  useImperativeHandle(ref, () => ({
    hide
  }))

  return(<ModalBase ref={childRef} open={open} onClose={onClose} zIndex={zIndex ? zIndex : ModalZIndex.compact}>
    <div ref={wrapperRef} className={styles.wrapper}>
      <div className={styles.content}>
        <ScrollableContainer>
          {children}
        </ScrollableContainer>
        {(!hideClose && onClose) && <div className={styles.closeButton}>
          <CloseButton onClose={() => {
            Audio.playSE('menuClose')
            hide()
          }}/>
        </div>}
      </div>
    </div>
  </ModalBase>)
}

export default forwardRef(Modal)
