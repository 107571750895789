//https://reffect.co.jp/react/react-usecontext-understanding
//https://dev.to/zettadam/how-to-handle-multiple-modals-in-a-react-application-2pei

import {createContext, useContext, useState} from 'react';
import {useLocalStorage} from "../hooks/useLocalStorage";
import {LocalStorageKeys} from "../constants/AppConstant";
import {useLocalStorageContext} from "./LocalStorageContext";
import {useLocation} from "react-router-dom";
import {GetQueriesBySearch} from "../utils/common";
import DebugConstants from "../constants/DebugConstants";

/** Debug機能 **/
const killAnimation = DebugConstants.getValues('Top', 'killAnimation')

export const TopState = {
  // ローカルストレージモーダル表示
  "confirmLocalStorage": "confirmLocalStorage",

  // ホームアイコン追加モーダル表示
  "addHomeModal": "addHomeModal",

  // ストーリー再開モーダル表示
  "continue": "continue",

  // アニメーション再生
  "playAnimation": "playAnimation",

  // ストーリー選択を表示
  "showMain": "showMain",

  // アニメーションをスキップして、メニューを表示
  "skipAnimAndShowMain": "skipAnimAndShowMain"
}

const TopStateContext = createContext()

const AnimatedTopLS = '_animatedTop'

export function useTopStateContext() {
  return useContext(TopStateContext)
}

/**
 * Top.js(/top)で、topStateによって表示を切り替える
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const TopStateProvider = ({children}) => {

  /*********************************************
   * サイト訪問時に、何を表示するかを判別
   *********************************************/
  // ?topState=showMainの場合にTopAnimationをスキップ
  const search = useLocation().search
  const queries = GetQueriesBySearch(search)
  const animatedTop = window.sessionStorage.getItem(AnimatedTopLS)
  const skipAnimAndShowMainFlg = killAnimation || (queries && queries.topState === 'showMain') || !!animatedTop

  // ローカルストレージの使用案内モーダル
  const [confirmLocalStorage, setConfirmLocalStorage] = useLocalStorage(LocalStorageKeys.ConfirmLocalStorage)
  const showConfirmLocalStorageFlg = !confirmLocalStorage

  // ホーム画面にアイコンを追加できますモーダル
  // 1回目のおしまい後のTOPで表示
  const [addHomeIcon, setAddHomeIcon] = useLocalStorage(LocalStorageKeys.AddHomeIcon)
  // const [completeCount] = useLocalStorage(LocalStorageKeys.CompleteCount)
  // const firstComplete = Number(completeCount) >= 1 // TOPを経由せずに違うお話を読む場合もあるので、1以上にしている
  const showAddHomeIconFlg = !addHomeIcon
  // const showAddHomeIconFlg = true

  // つづきから読むモーダル
  const {userStoryData} = useLocalStorageContext()
  const showContinueFlg = userStoryData && userStoryData.storyId && userStoryData.sceneId

  // 上記のモーダルがすべて表示し終えたら、アニメーションを再生
  const playAnimationFlg = !showConfirmLocalStorageFlg && !showContinueFlg

  /*********************************************
   * 1番最初に表示するものを決める
   *********************************************/
  let initState
  if(showConfirmLocalStorageFlg) {
    initState = TopState.confirmLocalStorage
  } else if(showAddHomeIconFlg) {
    initState = TopState.addHomeModal
  } else if(showContinueFlg) {
    initState = TopState.continue
  } else if(skipAnimAndShowMainFlg) {
    initState = TopState.skipAnimAndShowMain
  } else if(playAnimationFlg) {
    initState = TopState.playAnimation
  }
  const [topState, setTopState] = useState(initState)


  /*********************************************
   * 関数
   *********************************************/
  // ホームアイコン追加モーダル表示
  const showAddHomeIcon = () => {
    setTopState(TopState.addHomeModal)
  }
  // つづきから読むモーダル表示
  const showContinue = () => {
    setTopState(TopState.continue)
  }
  // topのアニメーションを再生
  const playAnimation = () => {
    if(skipAnimAndShowMainFlg) {
      setTopState(TopState.skipAnimAndShowMain)
    } else {
      setTopState(TopState.playAnimation)
    }
  }
  // ストーリー選択メニューなど表示
  const showMain = () => {
    window.sessionStorage.setItem(AnimatedTopLS, 'true')
    setTopState(TopState.showMain)
  }

  /**
   * 今の状況(currentTopState)から、次に何をすべきか判断する関数
   * @param currentTopState {String}
   */
  const nextAction = (currentTopState) => {
    switch (currentTopState) {

      // 例）ローカルストレージ確認モーダルで(OK)をクリック後に何をすべきかを判定
      case TopState.confirmLocalStorage:
        setConfirmLocalStorage(true)
        if (showAddHomeIconFlg) {
          showAddHomeIcon()
        } else if(showContinueFlg) {
          showContinue()
        } else {
          playAnimation()
        }
        break

      case TopState.addHomeModal:
        setAddHomeIcon(true)
        if(showContinueFlg) {
          showContinue()
        } else {
          playAnimation()
        }
        break

      case TopState.continue:
        playAnimation()
        break

      case TopState.playAnimation:
        showMain()
        break

      default:
        break
    }
  }

  const value = {
    topState,
    setTopState,
    showAddHomeIcon,
    nextAction
  }

  return (<TopStateContext.Provider value={value}>
    {children}
  </TopStateContext.Provider>);
};

export default TopStateProvider;
