import styles from "./ScrollableContainer.module.scss"

function ScrollableContainer({children}) {

  return (<div className={styles.wrapper}>
    {children}
  </div>)
}

export default ScrollableContainer
