import styles from "./FlexColumn.module.scss"

function FlexColumn({children, bottom}) {
  return (<div className={styles.wrapper}>
    <div className={styles.flexTop}>
      {children}
    </div>
    <div className={styles.flexBottom}>
      {bottom}
    </div>
  </div>)
}

export default FlexColumn
