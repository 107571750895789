import styles from "./ActiveMedal.module.scss"
import {ReplaceBR, Sanitize} from "../../../utils/common";

function ActiveMedal({data, detailOpen}) {
  const zeroPadId = data.id.padStart(2, '0')
  return (<button className={`${styles.card} ${styles.cardActive}`} onClick={detailOpen} data-id={data.id}>
    <div className={styles.inner}>
      <p className={styles.id}>{zeroPadId}</p>
      <div className={styles.medal}>
        <img className={styles.shadow} src={`/assets/img/medals/${zeroPadId}.png`} alt={data.title}/>
      </div>
      <p className={styles.title} dangerouslySetInnerHTML={{__html: Sanitize(ReplaceBR(data.title))}} />
    </div>
  </button>)
}

export default ActiveMedal
