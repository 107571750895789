import styles from "./HowTo.module.scss"
import {useRef, useState} from 'react';
import Button from "../../ui/buttons/Button";
import TitleAndCopySet from "../../partsSmall/titles/TitleAndCopySet";
import FlexColumn from "../../layouts/FlexColumn";
import PortalModal from "../../ui/modals/PortalModal";
import JsonData from "../../../data/JsonData";
import {GetStorageValue, Sanitize, SetStorageValue} from "../../../utils/common";
import {LocalStorageKeys} from "../../../constants/AppConstant";
import Modal from "../../ui/modals/Modal";
import gsap from "gsap";

import howToImage1 from "../../../img/howto/howto_1.jpg"
import howToImage2 from "../../../img/howto/howto_2.jpg"
import howToImage3 from "../../../img/howto/howto_3.jpg"

function InitHowTo() {
  const TextData = JsonData.textData

  /** StoryTopで、初回のあそびかたを表示するかどうか **/
  const showedInitHowTo = GetStorageValue(LocalStorageKeys.ShowedInitHowTo)
  const [open, setOpen] = useState(!showedInitHowTo)

  /** refs **/
  const initWrapRef = useRef()

  /** Flags **/
  // HowToのページを管理
  const [pageCount, setPageCount] = useState(0)

  /** Functions **/
  const countReset = () => {
    setPageCount(0)
  }
  const countUp = () => {
    scrollToTop()
    setPageCount(pageCount + 1)
  }
  const onClose = () => {
    setOpen(false)
    SetStorageValue(LocalStorageKeys.ShowedInitHowTo, true)
    countReset()
  }

  const scrollToTop = () => {
    const top = initWrapRef.current.scrollTop
    if(top > 0) {
      const data = {top}
      gsap.to(data, {
        top: 0,
        duration: 0.2,
        onUpdate: () => {
          initWrapRef.current.scrollTo(0, data.top)
        }
      })
    }
  }

  /** Element Functions **/
  const NextButton = () => {
    return <Button onClick={countUp} seName="button">つぎへ</Button>
  }
  const CloseButton = () => {
    return <Button onClick={onClose} color="brown" seName="button">{TextData.common.close}</Button>
  }

  return (
    <PortalModal open={open}>
      <Modal open={open} onClose={onClose}>

        <div ref={initWrapRef} className={styles.initWrap}>

          {/* 1 */}
          <div style={{display: (pageCount === 0) ? 'block' : 'none'}}>
            <FlexColumn bottom={<div className={styles.buttonWrap}><NextButton/></div>}>
              <div>
                <TitleAndCopySet
                  title={TextData.howto.title}
                >
                  <p className={styles.subTitle} dangerouslySetInnerHTML={{__html: Sanitize(TextData.howto.title1)}}/>
                  <img className={styles.image} src={howToImage1} alt=""/>
                  <p className={styles.explain} dangerouslySetInnerHTML={{__html: Sanitize(TextData.howto.text1)}}/>
                </TitleAndCopySet>
              </div>
            </FlexColumn>
          </div>

          {/* 2 */}
          <div style={{display: (pageCount === 1) ? 'block' : 'none'}}>
            <FlexColumn bottom={<div className={styles.buttonWrap}><NextButton/></div>}>
              <div>
                <TitleAndCopySet
                  title={TextData.howto.title}
                >
                  <p className={styles.subTitle} dangerouslySetInnerHTML={{__html: Sanitize(TextData.howto.title2)}}/>
                  <img className={styles.image} src={howToImage2} alt=""/>
                  <p className={styles.explain} dangerouslySetInnerHTML={{__html: Sanitize(TextData.howto.text2)}}/>
                </TitleAndCopySet>
              </div>
            </FlexColumn>
          </div>

          {/* 3 */}
          <div style={{display: (pageCount === 2) ? 'block' : 'none'}}>
            <FlexColumn bottom={<div className={styles.buttonWrap}><CloseButton/></div>}>
              <div>
                <TitleAndCopySet
                  title={TextData.howto.title}
                >
                  <p className={styles.subTitle} dangerouslySetInnerHTML={{__html: Sanitize(TextData.howto.title3)}}/>
                  <img className={styles.image} src={howToImage3} alt=""/>
                  <p className={styles.explain} dangerouslySetInnerHTML={{__html: Sanitize(TextData.howto.text3)}}/>
                </TitleAndCopySet>
              </div>
            </FlexColumn>
          </div>

        </div>

      </Modal>
    </PortalModal>)
}

export default InitHowTo
