import {useLocalStorage} from "../hooks/useLocalStorage";
import {LocalStorageKeys} from "../constants/AppConstant";

/**
 * ストーリーの進行状況をローカルストレージに保存
 *
 * ▼▼▼ この値の役割 ▼▼▼
 * ・コンティニューモーダル表示 → storyId と sceneId がある場合に表示
 * ・メダルの取得状況によって、ストーリーの分岐 → medals
 * ・URL直打ち対策 → sceneId と nexts にない sceneIdを含むURLを打ち込まれたときはリダイレクト
 *
 * ▼▼▼ 削除のタイミング ▼▼▼
 * (さいしょから読む)や、ストーリーが終わったタイミングで削除
 *
 * ▼▼▼ データ ▼▼▼
 * userStoryData = {
 *   date:   {Number} ローカルストレージをアップデートした時間,
 *   medals: {Array} ストーリー進行で取得したメダル,
 *   storyId: {String} 現在のストーリーID （例: momotaro
 *   sceneId: {String} 現在のシーンID （例: momotar-01_1
 *   nexts: {Array} 現在のシーンから進む可能性のある次のページのシーンID
 *   prev: {String} 現在からひとつ前のシーンID
 * }
 *
 * @constructor
 */
export const UserStoryDataLocalStorage = () => {
  const [userStoryData, setStoryMedal] = useLocalStorage(LocalStorageKeys.UserStoryData)

  /**
   * {LocalStorageKeys.UserStoryData}の`medals`を更新
   * @param medals {Array}
   */
  const updateMedals = (medals) => {
    const newObj = {...userStoryData, ...{
        date: new Date().getTime(),
        medals
      }}
    setStoryMedal(newObj)
  }

  /**
   * {LocalStorageKeys.UserStoryData}の`medals` を1つ追加したり、1つ削除したり、全て削除したり
   * @param type {String} add / remove / delete
   * @param medalId {Number}
   */
  const editUserStoryMedalData = (type, medalId) => {
    let medals = (userStoryData?.medals) ? userStoryData.medals : []
    switch (type) {
      case 'add':
        if(!medals) medals = []
        if(!medals.includes(medalId)) medals.push(medalId)
        updateMedals(medals)
        break

      case 'remove':
        if(!medals && !medals.includes(medalId)) return
        const newData = medals.filter(d => d !== medalId)
        updateMedals(newData)
        break

      case 'delete':
        setStoryMedal(null)
        break

      default:
        break
    }
  }

  /**
   * ユーザーのストーリー状況を記録
   * @param storyId {String}
   * @param sceneId {String}
   * @param nexts {Array}
   * @param medalId {Number}
   */
  const recordUserStoryData = (storyId, sceneId, nexts, medalId) => {
    let medals = userStoryData?.medals
    if(!medals) medals = []
    if(medalId && !medals.includes(medalId)) medals.push(medalId)

    // おしまい画面で、一つ前の絵を出す必要があるのでローカルストレージに保存。
    // またリロードで prev が userStoryData.sceneId に上書きされないようにしている
    let prev = null
    if(!!userStoryData?.sceneId) {
      prev = (userStoryData?.sceneId === sceneId) ? userStoryData.prev : userStoryData.sceneId
    }

    setStoryMedal({...userStoryData, ...{
        date: new Date().getTime(),
        medals,
        storyId,
        sceneId,
        nexts,
        prev: prev
      }})
  }

  /**
   * 現在のページの一つ前のsceneIdを保持 = おしまいページでの絵を表示するため
   * @param storyId
   * @param prev
   */
  const updateUserStoryPrevData = (storyId, prev) => {
    setStoryMedal({...userStoryData, ...{
        date: new Date().getTime(),
        storyId,
        prev: prev
      }})
  }

  return {userStoryData, editUserStoryMedalData, recordUserStoryData, updateUserStoryPrevData}
}
