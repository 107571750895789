import styles from "./Policy.module.scss"
import Button from "../../ui/buttons/Button";
import JsonData from "../../../data/JsonData";
import ModalProvider from "../../../contexts/ModalContext";
import Audio from "../../../audios/Audio";
import CloseButton from "../../ui/buttons/CloseButton";


const POLICY = [
  {
    head: "適用",
    content: [
      {text: "本規約は、本サービスの提供条件および本サービスの利用に関する当社と登録ユーザーとの間の権利義務関係を定めることを目的とし、登録ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されます。"},
      {text: "当社が当社ウェブサイト上で掲載する本サービス利用に関するルールは、本規約の一部を構成するものとします。"},
      {text: "本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。"
      }
    ]
  },
  {
    head: "定義",
    text: "本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。",
    content: [
      {text: "「サービス利用契約」とは、本規約を契約条件として当社と登録ユーザーの間で締結される、本サービスの利用契約を意味します。"},
      {text: "「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。"},
      {text: "「当社ウェブサイト」とは、そのドメインが「era-book.com」である、当社が運営するウェブサイト（理由の如何を問わず、当社のウェブサイトのドメインまたは内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。"},
      {text: "「登録ユーザー」とは、第3条（登録）に基づいて本サービスの利用者としての登録がなされた個人を意味します。"},
      {text: "「パートナー」とは、本サービスに関して当社が提携する外部の個人、法人または団体を意味します。"},
      {text: "「本サービス」とは、当社が「えらぶっく」という名称で提供する絵本型学習サービス（理由の如何を問わずサービスの名称または内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。なお、本サービス内容の詳細は、本規約で定める他、当社ウェブサイト等で表示するものとします。"
      }
    ]
  },
  {
    head: "登録",
    content: [
      {text: "本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録事項」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。"},
      {text: "当社は、当社の基準に従って、第1項に基づいて登録申請を行った登録希望者（以下「登録申請者」といいます。）の登録の可否を判断し、当社が登録を認める場合にはその旨を登録申請者に通知します。登録申請者の登録ユーザーとしての登録は、当社が本項の通知を行ったことをもって完了したものとします。"},
      {text: "前項に定める登録の完了時に、サービス利用契約が登録ユーザーと当社の間に成立し、登録ユーザーは本サービスを本規約に従い利用することができるようになります。"},
      {text: "当社は、登録申請者が、以下の各号のいずれかの事由に該当する場合は、登録および再登録を拒否することがあり、またその理由について一切開示義務を負いません。",
        content: [
          {
            text: "当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合"
          },
          {
            text: "未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合"
          },
          {
            text: "反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合"
          },
          {
            text: "過去当社との契約に違反した者またはその関係者であると当社が判断した場合"
          },
          {
            text: "第11条に定める措置を受けたことがある場合"
          },
          {
            text: "その他、登録を適当でないと当社が判断した場合"
          }
        ]
      }
    ]
  },
  {
    head: "登録事項の変更",
    content: [
      {text: "登録ユーザーは、登録事項に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。"}
    ]
  },
  {
    head: "パスワードおよびユーザーIDの管理",
    content: [
      {text: "登録ユーザーは、自己の責任において、本サービスに関するパスワードおよびユーザーIDを適切に管理および保管するものとし、当社が承諾する範囲を除き、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。"},
      {text: "パスワードまたはユーザーIDの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は登録ユーザーが負うものとします。"}
    ]
  },
  {
    head: "本サービスの利用及び料金",
    content: [
      {text: "登録ユーザーは、本規約の条件に従うことを条件に、本サービスを利用することができます。"},
      {text: "本サービスは、無料サービスとなっております。"}
    ]
  },
  {
    head: "禁止事項",
    text: "登録ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。",
    content: [
      {text: "法令に違反する行為または犯罪行為に関連する行為"},
      {text: "当社、本サービスの他の利用者、パートナーまたはその他の第三者に対する詐欺、脅迫、ストーカー行為"},
      {text: "公序良俗に反する行為"},
      {text: "当社、本サービスの他の利用者、パートナーまたはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為"},
      {text: "本サービスを当社が定める目的以外で使用する行為"},
      {text: "本サービスを通じ、以下に該当し、または該当すると当社が判断する情報を本サービス上に投稿し、当社または本サービスの他の利用者またはパートナーに送信すること",
        content: [
          {text: "過度に暴力的または残虐な表現を含む情報"},
          {text: "当社、本サービスの他の利用者、パートナーまたはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する情報"},
          {text: "第三者の同意なく当該第三者の個人情報を含んだ情報"},
          {text: "コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報"},
          {text: "当社、本サービスの他の利用者、パートナーまたはその他の第三者の名誉または信用を毀損する表現を含む情報"},
          {text: "過度にわいせつな表現を含む情報"},
          {text: "差別を助長する表現を含む情報"},
          {text: "自殺、自傷行為を助長する表現を含む情報"},
          {text: "薬物の不適切な利用を助長する表現を含む情報"},
          {text: "反社会的な表現を含む情報"},
          {text: "チェーンメール等の第三者への情報の拡散を求める情報"},
          {text: "他人に不快感を与える表現を含む情報"}
        ]
      },
      {text: "本サービスのネットワークまたはシステム等に過度な負荷をかける行為"},
      {text: "当社が提供するソフトウェアその他のシステムに対するリバースエンジニアリングその他の解析行為"},
      {text: "本サービスの運営を妨害するおそれのある行為"},
      {text: "当社のネットワークまたはシステム等への不正アクセス"},
      {text: "第三者に成りすます行為"},
      {text: "本サービスの他の利用者のIDまたはパスワードを利用する行為"},
      {text: "当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為"},
      {text: "本サービスの他の利用者の情報の収集"},
      {text: "当社、本サービスの他の利用者、パートナーまたはその他の第三者に不利益、損害、不快感を与える行為"},
      {text: "反社会的勢力等への利益供与"},
      {text: "面識のない異性との出会いを目的とした行為"},
      {text: "前各号の行為を直接または間接に惹起し、または容易にする行為"},
      {text: "前各号の行為を試みること"},
      {text: "その他、当社が不適切と判断する行為"}
    ]
  },
  {
    head: "本サービスの停止等",
    text: "当社は、以下のいずれかに該当する場合には、登録ユーザーに事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。",
    content: [
      {text: "本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合"},
      {text: "コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合"},
      {text: "地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合"},
      {text: "その他、当社が停止または中断を必要と判断した場合"},
    ]
  },
  {
    head: "権利帰属",
    content: [
      {text: "当社ウェブサイトおよび本サービスに関する知的財産権は全て当社または当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、当社ウェブサイトまたは本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。"},
      {text: "登録ユーザーは、自らが投稿その他送信する情報についての適法な権利を有していること、および当該情報が第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。（フィードバック）"},
      {text: "登録ユーザーが、本サービスまたは当社に関連して改善、提案、その他のフィードバック（総称して、以下「フィードバック」といいます。）を提供した場合、登録ユーザーは、当社に対し、当該フィードバックに関する世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示および実行に関するライセンスを付与します。"},
    ]
  },
  {
    head: "登録抹消等",
    content: [
      {
        text: "当社は、登録ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、当該登録ユーザーについて本サービスの利用を一時的に停止し、または登録ユーザーとしての登録を抹消することができます。",
        content: [
          {text: "本規約のいずれかの条項に違反した場合"},
          {text: "登録事項に虚偽の事実があることが判明した場合"},
          {text: "支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始もしくはこれらに類する手続の開始の申立てがあった場合"},
          {text: "6ヶ月以上本サービスの利用がない場合"},
          {text: "当社からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない場合"},
          {text: "第3条第4項各号に該当する場合"},
          {text: "その他、当社が本サービスの利用または登録ユーザーとしての登録の継続を適当でないと判断した場合"},
        ]
      },
      {text: "前項各号のいずれかの事由に該当した場合、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。"},
      {text: "本条に基づき登録を抹消された登録ユーザーは、当社が承諾する場合を除き、本サービスに再登録できないものとします。"},
    ]
  },
  {
    head: "退会",
    content: [
      {text: "登録ユーザーは、当社所定の手続の完了により、本サービスから退会し、自己の登録ユーザーとしての登録を抹消することができます。"},
      {text: "退会にあたり、当社に対して負っている債務が有る場合は、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。"},
      {text: "退会後の利用者情報の取扱いについては、第16条の規定に従うものとします。"},
    ]
  },
  {
    head: "本サービスの内容の変更、終了",
    content: [
      {text: "当社は、当社の都合により、本サービスの内容を変更し、または提供を終了することができます。"},
      {text: "当社が本サービスの提供を終了する場合、当社は登録ユーザーに事前に通知するものとします。"}
    ]
  },
  {
    head: "保証の否認および免責",
    content: [
        {text: "当社は、本サービスが登録ユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、登録ユーザーによる本サービスの利用が登録ユーザーに適用のある法令または業界団体の内部規則等に適合すること、継続的に利用できること、不具合が生じないこと、および第三者の権利を侵害しないことについて、明示または黙示を問わず何ら保証するものではありません。"},
        {text: "登録ユーザーは本サービス上のコンテンツを保存等する場合、自己の責任において、保存またはバックアップ措置を講じるものとし、当該コンテンツの消滅、削除等について、当社は一切の責任を負わないものとします。"},
        {text: "当社は、本サービスに関して登録ユーザーが被った損害につき、1万円を超えて賠償する責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害および逸失利益にかかる損害については、賠償する責任を負わないものとします。但し、当社に故意又は重過失がある場合はこの限りではありません。"},
        {text: "本サービスまたは当社ウェブサイトに関連して登録ユーザーと他の登録ユーザー、パートナーまたは第三者との間において生じた取引、連絡、紛争等については、登録ユーザーが自己の責任によって解決するものとします。"},
        {text: "当社は、本サービスが全ての情報端末に対応していることを保証するものではなく、本サービスの利用に供する情報端末のOSのバージョンアップ等に伴い、本サービスの動作に不具合が生じる可能性があることにつき、登録ユーザーはあらかじめ承諾するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。"},
    ]
  },
  {
    head: "秘密保持",
    text: "登録ユーザーは、本サービスに関連して当社が登録ユーザーに対して秘密に取扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取扱うものとします。"
  },
  {
    head: "利用者情報の取扱い",
    content: [
      {text: "当社による登録ユーザーの利用者情報の取扱いについては、別途当社プライバシーポリシーの定めによるものとし、登録ユーザーはこのプライバシーポリシーに従って当社が登録ユーザーの利用者情報を取扱うことについて同意するものとします。"},
      {text: "当社は、登録ユーザーが当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用および公開することができるものとし、登録ユーザーはこれに異議を唱えないものとします。"}
    ]
  },
  {
    head: "本規約等の変更",
    text: "当社は、当社が必要と認めた場合は、本規約を変更できるものとします。本契約を変更する場合、変更後の本規約の施行時期および内容を当社ウェブサイト上での掲示その他の適切な方法により周知し、または登録ユーザーに通知します。ただし、法令上登録ユーザーの同意が必要となるような内容の変更の場合は、当社所定の方法で登録ユーザーの同意を得るものとします。"
  },
  {
    head: "連絡／通知",
    content: [
      {text: "本サービスに関する問い合わせその他登録ユーザーから当社に対する連絡または通知、および本規約の変更に関する通知その他当社から登録ユーザーに対する連絡または通知は、当社の定める方法で行うものとします。"},
      {text: "当社が登録事項に含まれるメールアドレスその他の連絡先に連絡または通知を行った場合、登録ユーザーは当該連絡または通知を受領したものとみなします。"},
    ]
  },
  {
    head: "サービス利用契約上の地位の譲渡等",
    content: [
      {text: "登録ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。"},
      {text: "当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利および義務ならびに登録ユーザーの登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、登録ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。"},
    ]
  },
  {
    head: "分離可能性",
    text: "本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定および一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。"
  },
  {
    head: "言語",
    text: "本規約は、日本語を正文とします。本規約について英語その他の言語による翻訳が作成される場合も参考のためにのみ作成されるにすぎず、翻訳は本規約の効力および解釈に何らの影響も及ぼさないものとします。"
  },
  {
    head: "準拠法および管轄裁判所",
    content: [
      {text: "本規約およびサービス利用契約の準拠法は日本法とします。"},
      {text: "本規約またはサービス利用契約に起因し、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。"},
    ]
  }
]


function Policy() {
  const TextData = JsonData.textData

  const historyBack = () => {
    Audio.playSE('button')
    window.history.back()
  }

  const smoothScroll = e => {
    e.preventDefault()
    const href = e.currentTarget.getAttribute('href')
    const targetId = href.split('#')[1]
    const targetEl = document.getElementById(targetId)
    const offsetY = targetEl.getBoundingClientRect().top + window.scrollY - 10
    document.getElementById('root').scrollTo({
      top: offsetY,
      behavior: "smooth"
    })
  }

  return (<ModalProvider>
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <header className={styles.header}>
          <h2 className={styles.title}>利用規約</h2>
          <p className={styles.explain}>
            本利用規約（以下「本規約」といいます。）には、本サービスの提供条件およびI&CO合同会社（以下「当社」といいます。）と登録ユーザーの皆様との間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。
          </p>
        </header>

        <div className={styles.policyIndex}>
          <h3>目次</h3>
          <ul>
            {POLICY.map((data, index) => {
              return <li key={`index_${index}`}><a onClick={(e) => {smoothScroll(e)}} href={`#${String(index)}`}>第{index + 1}条（{data.head}）</a></li>
            })}
          </ul>
        </div>

        <div className={styles.policyMain}>

          {POLICY.map((data, index) => {
            return <dl key={`parent_${index}`} id={String(index)}>
              <dt>第{index + 1}条（{data.head}）</dt>
              <dd>
                {data.text && <p>{data.text}</p>}
                {data.content && <ol>
                  {data.content.map((list, listIndex) => {
                    return <li key={`${index}_${listIndex}`}>
                      <p>{list.text}</p>
                      {list.content && <ol className={styles.childOl}>
                        {data.content.map((childList, childListIndex) => {
                          return <li key={`${index}_${listIndex}_${childListIndex}`}><p>{childList.text}</p></li>
                        })}
                      </ol>}
                    </li>
                  })}
                </ol>}
              </dd>
            </dl>
          })}

          <p className={styles.date}>【2022年4月10日制定】</p>
        </div>

        <div className={styles.buttonWrap}>
          <Button onClick={historyBack}>{TextData.common.close}</Button>
        </div>
      </div>
    </div>
    <div className={styles.closeButton}>
      <CloseButton onClose={historyBack}/>
    </div>
  </ModalProvider>)
}

export default Policy
