import styles from "./ConfirmLocalStorageModal.module.scss"
import React, {useRef} from 'react';
import Button from "../../ui/buttons/Button";
import Modal from "../../ui/modals/Modal";
import JsonData from "../../../data/JsonData";
import {isMobile, isIOS, isAndroid} from 'react-device-detect';
import {Sanitize} from "../../../utils/common";
import PortalModal from "../../ui/modals/PortalModal";
import DotsSlider from "../Common/DotsSlider";
import chromeImage from "../../../img/top/pwa/chrome.jpg"
import edgeImage from "../../../img/top/pwa/edge.jpg"
import ios1 from "../../../img/top/pwa/ios_01.jpg"
import ios2 from "../../../img/top/pwa/ios_02.jpg"
import ios3 from "../../../img/top/pwa/ios_03.jpg"
import ios4 from "../../../img/top/pwa/ios_04.jpg"
import android1 from "../../../img/top/pwa/android_01.jpg"
import android2 from "../../../img/top/pwa/android_02.jpg"
import android3 from "../../../img/top/pwa/android_03.jpg"
import android4 from "../../../img/top/pwa/android_04.jpg"
import android5 from "../../../img/top/pwa/android_05.jpg"

const SLIDE_DATA = {
  ios: [{
    title: JsonData.textData.homeIcon.mobileTitle,
    explain: JsonData.textData.homeIcon.ios1,
    note: JsonData.textData.homeIcon.note,
    imagePath: ios1
  }, {
    title: JsonData.textData.homeIcon.step1,
    explain: JsonData.textData.homeIcon.ios2,
    imagePath: ios2
  }, {
    title: JsonData.textData.homeIcon.step2,
    explain: JsonData.textData.homeIcon.ios3,
    imagePath: ios3
  }, {
    title: JsonData.textData.homeIcon.step3,
    explain: JsonData.textData.homeIcon.ios4,
    imagePath: ios4
  }], android: [{
    title: JsonData.textData.homeIcon.mobileTitle,
    explain: JsonData.textData.homeIcon.android1,
    note: JsonData.textData.homeIcon.note,
    imagePath: android1
  }, {
    title: JsonData.textData.homeIcon.step1,
    explain: JsonData.textData.homeIcon.android2,
    imagePath: android2
  }, {
    title: JsonData.textData.homeIcon.step2,
    explain: JsonData.textData.homeIcon.android3,
    imagePath: android3
  }, {
    title: JsonData.textData.homeIcon.step3,
    explain: JsonData.textData.homeIcon.android4,
    imagePath: android4
  }, {
    title: JsonData.textData.homeIcon.step4,
    explain: JsonData.textData.homeIcon.android5,
    imagePath: android5
  }]
}

function AddHomeIconModal({open, onClose}) {
  /** JSONデータ **/
  const TextData = JsonData.textData

  /** variables **/
  let slideData = null
  if (isMobile && isIOS) slideData = SLIDE_DATA['ios']
  if (isMobile && isAndroid) slideData = SLIDE_DATA['android']

  /** Functions **/
  const onClick = () => {
    if (onClose) onClose()
  }

  /** **/
  const childRef = useRef()
  const hide = () => {
    childRef.current.hide()
  }

  return <PortalModal open={open}>
    <Modal ref={childRef} open={open} onClose={onClick}>

      {((isMobile && isIOS) || isAndroid) && <DotsSlider data={slideData} hide={hide}>
        {slideData.map((data, index) => {
          return <div key={index} className={styles.slide}>
            <p className={styles.title} dangerouslySetInnerHTML={{__html: Sanitize(data.title)}}/>
            <div className={styles.textWrap}>
              <div>
                <p className={styles.text} dangerouslySetInnerHTML={{__html: Sanitize(data.explain)}}/>
                {data.note && <p className={styles.note} dangerouslySetInnerHTML={{__html: Sanitize(data.note)}}/>}
              </div>
            </div>
            <div className={styles.image}>
              <img src={data.imagePath} alt=""/>
            </div>
          </div>
        })}
      </DotsSlider>}
      {/*PC*/}
      {!isMobile && <div className={styles.wrapper}>
        <div className={styles.flexTop}>
          <div>
            <p className={styles.title} dangerouslySetInnerHTML={{__html: Sanitize(TextData.homeIcon.pcTitle)}}/>
            <div className={styles.textWrap}>
              <p className={styles.text} dangerouslySetInnerHTML={{__html: Sanitize(TextData.homeIcon.forPc)}}/>
            </div>
            <ul className={styles.screenshot}>
              <li>
                <img src={edgeImage} alt=""/>
                <p>{TextData.homeIcon.forEdge}</p>
              </li>
              <li>
                <img src={chromeImage} alt=""/>
                <p>{TextData.homeIcon.forChrome}</p>
              </li>
            </ul>
          </div>

        </div>
        <div className={styles.flexBottom}>
          <Button onClick={hide} color={"brown"} seName="button">{TextData.common.ok}</Button>
        </div>
      </div>}
    </Modal>
  </PortalModal>
}

export default AddHomeIconModal
