function PictureImage({positions, src}) {
  const style = (positions) ?
    {objectPosition: (positions) ? `${positions.x} ${positions.y}` : 'center'} :
    {}

  return(
    <img
      src={src}
      alt=""
      style={style}
    />)
}

export default PictureImage
