import About from "../components/pages/About/About";
import Medals from "../components/pages/Medals/Medals";
import HowTo from "../components/pages/HowTo/HowTo";
import {useModalContext} from "../contexts/ModalContext";
import MedalDetail from "../components/pages/MedalDetail/MedalDetail";

export const ModalStates = {
  about: "about",
  howTo: "howTo",
  medal: "medal",
  // メダル詳細モーダルのみ表示(おしまいのページ用)
  medalDetail: "medalDetail",
  // メダル一覧のモーダル上に、メダル詳細モーダル表示時
  medalOnMedalDetail: "medalOnMedalDetail",
}

const ModalManager = ({medalDetailId}) => {
  const {selectedModal, closeModal} = useModalContext()

  const onClose = () => {
    // ”メダル一覧”から”メダル詳細”を開いた場合、”メダル詳細”の戻り先は”メダル一覧”
    if(selectedModal === ModalStates.medalOnMedalDetail) {
      closeModal(ModalStates.medal)
    } else {
      closeModal()
    }

  }

  return (
    <>
      <About open={selectedModal === ModalStates.about} onClose={onClose} />
      <HowTo open={selectedModal === ModalStates.howTo} onClose={onClose} />
      <Medals open={selectedModal === ModalStates.medal || selectedModal === ModalStates.medalOnMedalDetail} onClose={onClose} />
      <MedalDetail open={selectedModal === ModalStates.medalDetail || selectedModal === ModalStates.medalOnMedalDetail} onClose={onClose} medalDetailId={medalDetailId} />
    </>
  );
};

export default ModalManager;
