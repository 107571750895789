import styles from "./HowTo.module.scss"
import TitleAndCopySet from "../../partsSmall/titles/TitleAndCopySet";
import PortalModal from "../../ui/modals/PortalModal";
import ModalFull from "../../ui/modals/ModalFull";
import JsonData from "../../../data/JsonData";
import howToImage1 from "../../../img/howto/howto_1.jpg";
import howToImage2 from "../../../img/howto/howto_2.jpg";
import howToImage3 from "../../../img/howto/howto_3.jpg";
import Button from "../../ui/buttons/Button";
import {Sanitize} from "../../../utils/common";
import {useRef} from "react";

function HowTo({open = false, onClose = null}) {
  const TextData = JsonData.textData

  const childRef = useRef()
  const hide = () => {
    childRef.current.hide()
  }


  return (
    <PortalModal open={open}>

      <ModalFull ref={childRef} open={open} onClose={onClose}>
        <div className={styles.wrapper}>
          {/* 1 */}
          <div className={styles.box}>
            <TitleAndCopySet
              title={TextData.howto.title}
              copy={TextData.howto.title1}
            >
              <p dangerouslySetInnerHTML={{__html: Sanitize(TextData.howto.text1)}}/>
            </TitleAndCopySet>
            <img className={styles.image} src={howToImage1} alt=""/>
          </div>

          {/* 2 */}
          <div className={styles.box}>
            <TitleAndCopySet
              copy={TextData.howto.title2}
            >
              <p dangerouslySetInnerHTML={{__html: Sanitize(TextData.howto.text2)}}/>
            </TitleAndCopySet>
            <img className={styles.image} src={howToImage2} alt=""/>
          </div>

          {/* 3 */}
          <div className={styles.box}>
            <TitleAndCopySet
              copy={TextData.howto.title3}
            >
              <p dangerouslySetInnerHTML={{__html: Sanitize(TextData.howto.text3)}}/>
            </TitleAndCopySet>
            <img className={styles.image} src={howToImage3} alt=""/>
          </div>

          <div className={styles.closeButton}>
            <Button onClick={hide} color="brown" seName={"menuClose"}>{TextData.common.close}</Button>
          </div>
        </div>
      </ModalFull>
    </PortalModal>)
}

export default HowTo
