import styles from './About.module.scss'
import Button from "../../ui/buttons/Button";
import TitleAndCopySet from "../../partsSmall/titles/TitleAndCopySet";
import ModalFull from "../../ui/modals/ModalFull";
import PortalModal from "../../ui/modals/PortalModal";
import JsonData from "../../../data/JsonData";
import {Sanitize} from "../../../utils/common";
import {useRef} from "react";
import {useBudouX} from "../../../hooks/useBudouX";

function About({open = false, onClose = null}) {
  const { parse } = useBudouX()
  const TextData = JsonData.textData

  const childRef = useRef()
  const hide = () => {
    childRef.current.hide()
  }
  return (
    <PortalModal open={open}>
      <ModalFull ref={childRef} open={open} onClose={onClose}>
        <div className={styles.wrapper}>
          <div>
            <TitleAndCopySet
              title={TextData.about.title}
            >
              <p className={styles.catch} dangerouslySetInnerHTML={{__html: parse(Sanitize(TextData.about.headline))}} />
              <p className={styles.explain} dangerouslySetInnerHTML={{__html: parse(Sanitize(TextData.about.text), 3000)}} />
            </TitleAndCopySet>
            <div className={styles.buttonWrap}><Button onClick={hide} color="brown" seName="menuClose">{TextData.common.close}</Button></div>
          </div>
        </div>
      </ModalFull>
    </PortalModal>
  )
}

export default About
