import styles from "./Entrance.module.scss"
import JsonData from "../../../data/JsonData";
import Button from "../../ui/buttons/Button";
import {useEffect} from "react";
import {Sanitize} from "../../../utils/common";
import lottie from "lottie-web";

function Entrance() {
  const TextData = JsonData.textData

  /**
   * トップのアニメーションのLottieを作成
   */
  const createLottie = () => {
    return lottie.loadAnimation({
      container: document.getElementById('lottie'),
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: `/assets/lottie/logo.json`,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    })
  }

  useEffect(() => {
    const lottieObj = createLottie()
    let timerId = null
    lottieObj.play()
    lottieObj.addEventListener('complete', () => {
      timerId = setTimeout(() => {
        lottieObj.goToAndPlay(90, true)
      }, 2000)
    })
    return () => {
      if(lottieObj) lottieObj.destroy()
      if(timerId) clearTimeout(timerId)
    }
  }, [])

  return (<div className={styles.wrapper}>
    <div className={styles.centering}>
      <div>
        <h1 id="lottie" className={styles.titleWrap}><span>{TextData.common.title}</span></h1>
        <div className={styles.buttonWrap}>
          <Button href={'/top'} seName="button">{TextData.entrance.start}</Button>
        </div>
        <p className={styles.sound} dangerouslySetInnerHTML={{__html: Sanitize(TextData.entrance.sound)}} />
      </div>
    </div>
    {/*<div className={styles.characters}>*/}
    {/*  <div className={styles.momotaro}><img src={chara1} alt=""/></div>*/}
    {/*  <div className={styles.pigs}><img src={chara2} alt=""/></div>*/}
    {/*  <div className={styles.antgrass}><img src={chara3} alt=""/></div>*/}
    {/*</div>*/}

    {/*webFontをエントランスで読み込む*/}
    <div className={styles.allText}>
      <div>
        {Object.keys(TextData).map(page => {
          return Object.keys(TextData[page]).map(textId => {
            return <p key={`${page}${textId}`}>{TextData[page][textId]}</p>
          })
        })}
      </div>
      <div>
        {Object.keys(TextData).map(page => {
          return Object.keys(TextData[page]).map(textId => {
            return <p key={`${page}${textId}`}>{TextData[page][textId]}</p>
          })
        })}
      </div>
      <div>
        {Object.keys(TextData).map(page => {
          return Object.keys(TextData[page]).map(textId => {
            return <p key={`${page}${textId}`}>{TextData[page][textId]}</p>
          })
        })}
      </div>
      <div>
        {Object.keys(TextData).map(page => {
          return Object.keys(TextData[page]).map(textId => {
            return <p key={`${page}${textId}`}>{TextData[page][textId]}</p>
          })
        })}
      </div>
    </div>
  </div>)
}

export default Entrance
