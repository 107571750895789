const isLocalhost =Boolean(
  window.location.hostname === 'localhost' ||
  window.location.hostname.startsWith('192')
);
const isProdServer = window.location.hostname === "era-book.com"
const isDevServer = !isProdServer

export const Env = {
  // isDevelopment: process.env.NODE_ENV === 'development',
  isDevelopment: isLocalhost || isDevServer,
  isProduction:  process.env.NODE_ENV === 'production'
}
if(Env.isDevelopment) {
  console.warn('isDevelopment')
}

export const LocalStorageKeys = {
  ConfirmLocalStorage: '_confirm_local_storage',
  AddHomeIcon: '_add_home_icon',
  ShowedMedalComplete: '_showed_medal_complete',
  GeneralMedals: '_general-medals',
  UserStoryData: '_user_story_data',
  ShowedInitHowTo: '_showed_init_howto',
  CompleteCount: '_complete_count',
}

export const ModalZIndex = {
  compact: 1001,
  full: 1000,
  menu: 999
}

