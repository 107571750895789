import React from 'react';
import ReactDOM from 'react-dom';
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './scss/variables.scss'
import './scss/_reset.scss'
import './scss/global.scss'
import {BrowserRouter as Router} from "react-router-dom";
import Audio from "./audios/Audio";

Audio.load()
console.log("v0.9.3")

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

serviceWorkerRegistration.register({
  onUpdate: registration => {
    console.log("ON UPDATE::", registration);
    if (registration.waiting) {
      registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload();
    }
  },
});

// serviceWorkerRegistration.unregister();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
