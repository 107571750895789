import styles from "./Debug.module.scss"
import {LocalStorageKeys} from "../../../constants/AppConstant";
import DebugConstants, {DebugExplain} from "../../../constants/DebugConstants";

const Explain = {
  [LocalStorageKeys.ConfirmLocalStorage]: `ローカルストレージ使用モーダル`,
  [LocalStorageKeys.AddHomeIcon]: `ホームアイコン追加モーダル`,
  [LocalStorageKeys.ShowedMedalComplete]: `メダルコンプリートモーダル`,
  [LocalStorageKeys.GeneralMedals]: `取得したメダル(メダルずかん用) `,
  [LocalStorageKeys.UserStoryData]: `ストーリー用ユーザーデータ(メダルなど)`,
  [LocalStorageKeys.ShowedInitHowTo]: `ストーリー初回あそびかた`,
  [LocalStorageKeys.CompleteCount]: `クリア回数`,
}

function Debug() {

  const deleteLocalStorage = key => {
    localStorage.removeItem(key)
    window.location.reload()
  }

  const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value)
    window.location.reload()
  }

  const completeMedals = () => {
    const obj = {
      date: new Date().getTime(),
      medals: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17"]
    }
    setLocalStorage('_general-medals', JSON.stringify(obj))
  }

  const clearAll = () => {
    Object.keys(LocalStorageKeys).forEach(key => {
      const lsKey = LocalStorageKeys[key]
      window.localStorage.removeItem(lsKey)
      window.location.reload()
    })
  }

  return (<div>
    <div className={styles.block}>
      <h2 className={styles.title}>LocalStorage</h2>
      <button style={{marginBottom: "10px"}} onClick={clearAll}>すべて削除</button>
      <div className={styles.scroll}>
        <table className={styles.table}>
          <tbody>
            {Object.keys(LocalStorageKeys).map(key => {
              const lsKey = LocalStorageKeys[key]
              return <tr key={lsKey} className={styles.dl}>
                <th>
                  <p>{key}</p>
                  <p>{Explain[lsKey]}</p>
                </th>
                <td><input type="text" defaultValue={localStorage[lsKey]}/></td>
                <td>
                  <button onClick={() => {
                    deleteLocalStorage(lsKey)
                  }}>削除
                  </button>
                </td>
                {(lsKey === '_general-medals') && <td>
                  <button onClick={() => {
                    completeMedals()
                  }}>コンプリート
                  </button>
                </td>}
                {(lsKey === '_showed_medal_complete') && <td>
                  <button onClick={() => {
                    completeMedals()
                    deleteLocalStorage(lsKey)
                    window.location.href = "./top"
                  }}>モーダル必ず表示
                  </button>
                </td>}
              </tr>
            })}
          </tbody>
        </table>
      </div>
    </div>
    <div className={styles.block}>
      <h2 className={styles.title}>デバック機能</h2>
      {Object.keys(DebugConstants.debugValues).map(pageId => {
        return <div key={pageId}>
          <h3 className={styles.subTitle}>{pageId}ページ</h3>
          <div className={styles.scroll}>
            <table className={styles.table}>
              <tbody>
                {Object.keys(DebugConstants.debugValues[pageId]).map(debugId => {
                  const data = DebugConstants.debugValues[pageId][debugId]
                  return <tr key={`${pageId}_${debugId}`} className={styles.dl}>
                    <th>
                      <p>{debugId}</p>
                      <p>{DebugExplain[debugId]}</p>
                    </th>
                    <td><input type="text" defaultValue={data.value}/></td>
                    <td>
                      <button onClick={() => {
                        DebugConstants.setValues(pageId, debugId, !data.value)
                        window.location.reload()
                      }}>{data.value ? `オフにする` : `オンにする`}</button>
                    </td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </div>
      })}
    </div>
  </div>)
}

export default Debug
