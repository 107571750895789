import styles from "./MedalDetail.module.scss"
import Modal from "../../ui/modals/Modal";
import Button from "../../ui/buttons/Button";
import MedalIdAndTitle from "../../partsSmall/titles/MedalIdAndTitle";
import JsonData from "../../../data/JsonData";
import {useEffect, useRef, useState} from "react";
import PortalModal from "../../ui/modals/PortalModal";
import {useBudouX} from "../../../hooks/useBudouX";

function MedalDetail(
  {
    open = false,
    onClose,
    medalDetailId = null
  }) {
  const { parse } = useBudouX()

  /** JSONデータ **/
  const TextData = JsonData.textData
  const MedalData = JsonData.medalData

  /** States  **/
  const [medalData, setMedalData] = useState()
  useEffect(() => {
    const data = (medalDetailId) ? MedalData.find(d => d.id === String(medalDetailId)) : null
    setMedalData(data)
  }, [medalDetailId])

  /**  **/
  const childRef = useRef()
  const hide = () => {
    childRef.current.hide()
  }

  if (!medalData) return ''

  const zeroPadId = medalData.id.padStart(2, '0')

  return <PortalModal open={open}>
    <Modal ref={childRef} open={open} onClose={onClose} zIndex={1001}>

      <div className={styles.wrapper}>
        <div>
          <header className={styles.header}>
            <MedalIdAndTitle medalId={zeroPadId} title={medalData.titleFlat}/>
          </header>

          <div className={styles.main}>
            <div className={styles.left}>
              <img src={`/assets/img/medals/${zeroPadId}.png`} alt={medalData.titleFlat}/>
            </div>
            <div className={styles.right}>
              <p className={styles.headline} dangerouslySetInnerHTML={{__html: parse(medalData.headline, 3000)}}/>
              <p className={styles.content} dangerouslySetInnerHTML={{__html: parse(medalData.content, 3000)}}/>
            </div>
          </div>
        </div>

        <ul className={styles.buttons}>
          {/* <li><Button href={medalData.link} seName='button' icon='external'>{TextData.common.more}</Button></li> */}
          <li><Button onClick={hide} color={"brown"} seName="menuClose">{TextData.common.close}</Button></li>
        </ul>

      </div>

    </Modal>
  </PortalModal>
}

export default MedalDetail
