import styles from "./IllustrationList.module.scss"
import {useMemo} from 'react';
import {useParams} from "react-router-dom";
import JsonData from "../../../data/JsonData";
import StoryPictures from "../../partsLarge/Story/StoryPictures";

function IllustrationList() {

  /** react-router-dom系 **/
  const params = useParams()

  /** storyId & urlSceneId **/
  const {storyId} = useMemo(() => (params), [params])

  /** StoriesData.json から storyId > urlSceneId のデータを取得 **/
  const storyData = JsonData.storiesData[storyId]

  return (<div>
    <ul className={styles.list}>
      {storyData.map((d, index) => {
        const sceneId = d.id.split("__")[0]
        const sceneBId = (d.type === "select") ? sceneId.replace("_a", "_b") : null

        return <li key={sceneId + index}>
          <h2>[{d.type}]<a href={`/s/${storyId}/${d.id}/?killRedirect=true`} target="_blank" rel="noreferrer">{sceneId}</a></h2>
          <p>{d.text}</p>
          <div className={styles.pictureWrap}>
            <StoryPictures storyId={storyId} sceneId={sceneId} index={index} />
          </div>
          {sceneBId && <div className={styles.pictureWrap}>
            <StoryPictures storyId={storyId} sceneId={sceneBId} index={index} />
          </div>}
        </li>
      })}
    </ul>
  </div>)
}

export default IllustrationList
