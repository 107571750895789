import React, {useEffect, useMemo, useState} from 'react';
import {motion} from "framer-motion"
import {useNavigate, useParams} from "react-router-dom";
import {CompareFlagAndLocalStorage} from "../../../utils/common";
import styles from './Story.module.scss'
import JsonData from "../../../data/JsonData";
import StoryNormal from "../../partsLarge/Story/StoryNormal";
import StoryGetMedal from "../../partsLarge/Story/StoryGetMedal";
import StorySelect from "../../partsLarge/Story/StorySelect";
import StoryMenu from "../../partsLarge/Common/StoryMenu";
import {useLocalStorageContext} from "../../../contexts/LocalStorageContext";
import DebugConstants from "../../../constants/DebugConstants";
import StoryDebug from "../../partsLarge/Story/StoryDebug";
import Audio from "../../../audios/Audio";
import StoryNextButton from "../../partsLarge/Story/StoryNextButton";
import StoryResult from "../../partsLarge/Story/StoryResult";
import ModalProvider from "../../../contexts/ModalContext";
import {useLocalStorage} from "../../../hooks/useLocalStorage";
import {LocalStorageKeys} from "../../../constants/AppConstant";

/** Debug機能 **/
const showMedalState = DebugConstants.getValues('Story', 'showMedalState')
const killRedirect = DebugConstants.getValues('Story', 'killRedirect')

function Story() {

  /** react-router-dom系 **/
  const navigate = useNavigate();
  const params = useParams()
  // const search = useLocation().search

  // paramsからstoryIdとsceneIdWithBranchIdを取得
  const {
    storyId,
    sceneId: sceneIdWithBranchId
  } = useMemo(() => (params), [params])

  // {sceneIdWithBranchId}は[{sceneId}__{branchId}]となっているので、__{branchId}を削除して、真のsceneIdを取り出す。
  // branchIdとは、同テキストだとsceneIdが重複するのでシステム側でbranchIdを付与している
  const hasBranch = sceneIdWithBranchId.includes('__')
  const sceneId = hasBranch ?
    sceneIdWithBranchId.split('__')[0] :
    sceneIdWithBranchId

  // 結果ページかどうがを判定
  const isResult = sceneIdWithBranchId.includes('result')

  /** LocalStorage **/
  const {
    addGeneralMedal,
    userStoryData,
    recordUserStoryData,
    updateUserStoryPrevData
  } = useLocalStorageContext()

  // おしまいにいった回数を数える for 1回クリア後のTOPで、PWAモーダルを表示するため
  const [completeCount, setCompleteCount] = useLocalStorage(LocalStorageKeys.CompleteCount)
  const incrementCompleteCount = () => {
    const count = (completeCount) ? Number(completeCount) : 0
    setCompleteCount(count + 1)
  }

  /** JSONデータ **/
  // テキストデータ
  const TextData = JsonData.textData
  // StoriesData(momotaro・pigs・antgrass)から、ストーリーデータを取得
  const StoryData = JsonData.storiesData[storyId]
  // 更にシーンに必要なデータを取得
  const sceneData = StoryData.find(d => d.id === sceneIdWithBranchId)
  const {type, text, next, select, branch, medalId} = sceneData

  /** useState **/
  // ページ遷移トランジションをするかしないか
  const [pageTransition, setPageTransition] = useState(false)
  // 分岐がある場合、条件によってnextが変わるため
  const [branchNext, setBranchNext] = useState(null)
  // つぎへなどのボタンがダブルクリックされないように
  const [preventDoubleClick, setPreventDoubleClick] = useState(false)

  /** 次のページの情報 **/
  // 次が結果ページかどうか
  const nextIsResult = (next) ? next.includes('_result') : false

  /** 共通のリダイレクト関数達 **/
  const redirect = (nextSceneId, href) => {
    if (nextSceneId) {
      navigate(`/s/${storyId}/${nextSceneId}`)
    } else {
      navigate(href)
    }
  }
  const redirectByBranch = (branchData) => {
    const nextBranchData = branchData.find(d => {
      return CompareFlagAndLocalStorage(d.flag, userStoryData.medals)
    })
    navigate(`/s/${storyId}/${nextBranchData.next}`)
  }

  /**
   * URL直打ち対策
   * 入力されたURLと、ローカルストレージの{userStoryData}を元にURL直打ちを管理
   */
  useEffect(() => {
    if (!killRedirect) {

      if (!!userStoryData && userStoryData.sceneId) {
        if (
          userStoryData?.sceneId === sceneIdWithBranchId ||
          userStoryData?.nexts?.includes(sceneIdWithBranchId)
        ) {
          /**
           * ユーザーが最後に見ていたページ(UserStoryData.sceneId)または、
           * その最後に見ていたページの次のページ(UserStoryData.nexts)
           * と、入力されたURLが、一致すればSTAY **/
        } else {
          /**
           * ユーザーが最後に見ていたページ(UserStoryData.sceneId)または、
           * その最後に見ていたページの次のページ(UserStoryData.nexts)
           * と、入力されたURLが、一致しなければ
           * ユーザーが最後に見ていたページにリダイレクト
           **/
          if(nextIsResult) {
            redirect(`${userStoryData.sceneId}`)
          } else {
            redirect(userStoryData.sceneId)
          }
        }
      } else {
        /** ユーザーのデータがない場合はストーリートップへリダイレクト **/
        redirect(false, `/s/${storyId}/`)
      }
    }
  }, [])


  /**
   * 状況に応じていろいろ
   */
  useEffect(() => {
    /************************
     * メダル取得した場合、メダル図鑑(GeneralMedals)に追加
     ************************/
    if (medalId) addGeneralMedal(medalId)

    /************************
     * ストーリーの進捗状況を記録
     * ************************/
    // つづきから読む機能のために、進捗を記録
    let nexts = []
    if (next) nexts.push(next)
    if (branchNext) nexts.push(branchNext)
    if (select) nexts.push(select.A.next)
    if (select) nexts.push(select.B.next)
    if (branch) {
      branch.forEach(d => {
        nexts.push(d.next)
      })
    }

    // 記録
    recordUserStoryData(storyId, sceneIdWithBranchId, nexts, medalId)

    /************************
     * ここから、Type別の処理。
     ************************/

    /**
     * Type: child [通常のページ]
     * branchがある場合、ユーザーのメダルの取得の状況によって<次へ>のリンク先を変更
     */
    if (type === 'child') {
      if (branch) {
        const branchData = branch.find(d => {
          if (!userStoryData.medals) console.log(`[WARN] userStoryData.medals が undefined`)
          return CompareFlagAndLocalStorage(d.flag, userStoryData.medals ? userStoryData.medals : [])
        })
        setBranchNext(branchData.next)
      }
    }

    /**
     * Type: chapter [チャプター]
     * nextか、branchに必ずリダイレクト
     */
    setTimeout(() => {
      if (type === 'chapter') {
        (branch) ? redirectByBranch(branch) : redirect(next)
      }
    }, 10)

  }, [])


  /************************
   * Functions
   ************************/

  /**
   * （つぎへ）（おしまい）ボタン挙動
   */
  const onNext = () => {
    if(preventDoubleClick) return
    setPreventDoubleClick(true)
    /**
     * userStoryDataのprevをアップデート
     */
    updateUserStoryPrevData(storyId, sceneId)

    /**
     * ページ推移アニメーションをオン
     */
    const onPageTransition =
      (type === 'child') || // type.normalのページ && 次のページが結果表示ではない場合はページ遷移アニメーションをon
      (type === 'select') ||                 // type.selectページの場合は問答無用でページ遷移アニメーションをon
      (type === 'medal')                     // type.medalページの場合も問答無用でページ遷移アニメーションをon
    setPageTransition(onPageTransition)

    Audio.playSE('nextPage')
    if(nextIsResult) {
      // おしまいへいった回数をアップデートする
      incrementCompleteCount()
      // 通常の次へ
      navigate(`/s/${storyId}/${next}`)
    }
    else if (next) {
      // 通常の次へ
      navigate(`/s/${storyId}/${next}`)
    }
    else if (branch) {
      // 分岐によってnextが変わる次へ
      navigate(`/s/${storyId}/${branchNext}`)
    }
  }

  /**
   * メインのコンポーネントを状況によって出し分け
   */
  const MainContent = () => {
    let component
    if(isResult) {
      component = <StoryResult sceneData={sceneData} storyId={storyId} />
    }
    else if(select) {
      component = <StorySelect key={"select"} storyId={storyId} sceneId={sceneId} select={select} setPageTransition={setPageTransition}/>
    }
    else if(medalId) {
      component = <StoryGetMedal key={"medal"} medalId={medalId}/>
    }
    else {
      component = <StoryNormal key={"normal"} text={text} storyId={storyId} sceneId={sceneId}/>
    }
    return component
  }

  return (
    <ModalProvider>
      <motion.div
        className={styles.pageTransition}
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={pageTransition ? {translateX: "-100px", width: 0} : {}}
        transition={{duration: 0.5}}
      >
        <div className={styles.wrapper}>

          {MainContent()}

          {(!select && !isResult) && <div className={styles.nextButton}>
            <StoryNextButton
              onNext={onNext}
              text={(nextIsResult) ? TextData.story.end : TextData.common.next} />
          </div>}

        </div>

      </motion.div>

      {/* メニュー */}
      {!isResult && <StoryMenu />}

      {/* デバッグ */}
      {showMedalState && <StoryDebug storyId={storyId} />}

    </ModalProvider>)
}

export default Story
