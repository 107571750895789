import styles from "./RotationBackground.module.scss"
import RotationSvg from "../../../img/common/rotation_bg.svg"

function RotationBackground() {

  return (<div className={styles.wrapper}>
    <div className={styles.inner}>
      <img className={styles.image} src={RotationSvg} alt=""/>
    </div>
  </div>)
}

export default RotationBackground
