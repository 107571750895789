import React, {useEffect, useRef} from 'react';
import gsap from "gsap"
import styles from "./StoryGetMedal.module.scss"
import TextMedalGetImage from "../../../img/story/txt_medal_get.png";
import MedalIdAndTitle from "../../partsSmall/titles/MedalIdAndTitle";
import JsonData from "../../../data/JsonData";
import Audio from "../../../audios/Audio";
import RotationBackground from "../Common/RotationBackground";

function StoryGetMedal({medalId}) {

  const titleEl = useRef()
  const medalEl = useRef()
  const detailEl = useRef()

  const zeroPadId = medalId.padStart(2, '0')
  /** メダル取得画面用に、メダルIDからメダルの詳細を取得 **/
  const medalData = (medalId) ? JsonData.medalData.filter(d => d.id === medalId)[0] : null

  useEffect(() => {
    let gsaps = []
    gsaps.push(gsap.fromTo(titleEl.current, {
      y: 30,
      opacity: 0
    }, {
      y: 0,
      opacity: 1,
      duration: 0.5
    }))
    gsaps.push(gsap.fromTo(medalEl.current, {
      y: 30,
      opacity: 0
    }, {
      y: 0,
      opacity: 1,
      duration: 0.5,
      delay: 0.2
    }))
    gsaps.push(gsap.fromTo(detailEl.current, {
      y: 30,
      opacity: 0
    }, {
      y: 0,
      opacity: 1,
      duration: 0.5,
      delay: 0.4
    }))
    let timerId = setTimeout(() => {
      Audio.playSE('getMedal')
    }, 500)
    return () => {
      clearTimeout(timerId)
      gsaps.forEach(g => {
        g.kill()
      })
    }
  }, [])


  return (<main className={styles.wrapper}>
    <RotationBackground />
    <div>
      <p ref={titleEl} className={styles.title}><img src={TextMedalGetImage} alt="メダルゲット"/></p>
      <div ref={medalEl} className={styles.medal}>
        <img src={`/assets/img/medals/${zeroPadId}.png`} alt=""/>
      </div>
      <div ref={detailEl} className={styles.detail}>
        <div className={styles.detailInner}>
          <MedalIdAndTitle medalId={zeroPadId} title={medalData?.titleFlat} />
        </div>
      </div>
    </div>
  </main>)
}

export default StoryGetMedal
