import styles from "./DotsSlider.module.scss";
import {useRef, useState} from "react";
import Slider from "react-slick";
import JsonData from "../../../data/JsonData";
import Button from "../../ui/buttons/Button";

function DotsSlider({children, data, hide}) {
  /** JSONデータ **/
  const TextData = JsonData.textData

  /** states **/
  const [slideIndex, setSlideIndex] = useState(0)

  /** refs **/
  const sliderRef = useRef()

  /** スライドSetting **/
  const settings = {
    dots: true,
    infinite: false,
    arrows: false,
    beforeChange: (current, next) => {
      setSlideIndex(next)
    }
  }
  return (<>
    <div className={styles.sliderWrap}>
      <div className={styles.slider}>
        <Slider ref={sliderRef} {...settings}>
          {children}
        </Slider>
        <ul className={styles.buttonWrap}>
          {(slideIndex === data.length - 1) &&
            <li><Button onClick={hide} color={"brown"} seName={"menuClose"}>{TextData.common.close}</Button></li>}
          {(slideIndex !== data.length - 1) && <li><Button seName={"button"} onClick={() => {
            if (sliderRef?.current) {
              sliderRef.current.slickNext()
            }
          }}>{TextData.common.next}</Button></li>}
        </ul>
      </div>
    </div>
  </>)
}

export default DotsSlider
