import {Routes, Route, useLocation} from "react-router-dom";
import Entrance from "./components/pages/Entrance/Entrance";
import Top from "./components/pages/Top/Top";
// import HowTo from "./components/pages/HowTo/HowTo";
// import About from "./components/pages/About/About";
// import Medals from "./components/pages/Medals/Medals";
import Policy from "./components/pages/Policy/Policy";
import StoryTop from "./components/pages/StoryTop/StoryTop";
import Story from "./components/pages/Story/Story";
import LocalStorageContext from "./contexts/LocalStorageContext";
import Debug from "./components/pages/Debug/Debug"
import IllustrationList from "./components/pages/Story/IllustrationList"
import {AnimatePresence} from 'framer-motion';
import {Env} from "./constants/AppConstant";
// import Contact from "./components/pages/Contact/Contact";

function App() {
  const location = useLocation();

  return (
    <LocalStorageContext>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Routes location={location} key={location.pathname}>
          {Env.isDevelopment && <Route path='/_debug' element={<Debug/>}/>}
          <Route path='/' element={<Entrance/>}/>
          <Route path='/top' element={<Top/>}/>
          {/*<Route path='/howto' element={<HowTo/>}/>*/}
          {/*<Route path='/about' element={<About/>}/>*/}
          {/*<Route path='/medals' element={<Medals/>}/>*/}
          <Route path='/policy' element={<Policy/>}/>
          {/*<Route path='/contact' element={<Contact/>}/>*/}
          <Route path='/s/:storyId' element={<StoryTop/>}/>
          {Env.isDevelopment && <Route path='/s/:storyId/_list' element={<IllustrationList/>}/>}
          <Route path='/s/:storyId/:sceneId' element={<Story/>}/>
        </Routes>
      </AnimatePresence>
    </LocalStorageContext>
  )
}

export default App
