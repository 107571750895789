import {useEffect, useState} from 'react';
import styles from "./TopAnimation.module.scss"
import lottie from "lottie-web";
import {TopState} from "../../../contexts/TopStateContext";
import Noise from "../../partsSmall/common/Noise";
import Audio from "../../../audios/Audio";

function TopAnimation({topState, onClose}) {

  const [topAnimLottie, setTopAnimLottie] = useState(null)

  /**
   * トップのアニメーションのLottieを作成
   */
  const createLottie = () => {
    return lottie.loadAnimation({
      container: document.getElementById('topAnimation'),
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: `/assets/lottie/webtop.json`,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    })
  }

  /**
   * トップのアニメーションを再生
   * state = playAnimation であれば、アニメーション再生
   * state = skipAnimAndShowMain であれば、アニメーションの最後のフレームにセット
   */
  const playLottie = (state = topState, lottieObj = topAnimLottie) => {
    if (state === TopState.playAnimation) {
      Audio.playBGM('bgm')
      // アニメーション再生
      lottieObj.play()
      lottieObj.addEventListener('complete', () => {
        if(onClose) onClose()
        lottieObj.goToAndPlay(180, true)
      }, {once: true})

    } else if (state === TopState.skipAnimAndShowMain) {
      Audio.playBGM('bgm')
      // アニメーションをスキップ
      lottieObj.goToAndPlay(180, true)
      lottieObj.addEventListener('complete', () => {
        lottieObj.goToAndPlay(180, true)
      })
    }
  }

  /**
   * トップのアニメーションのLottieを作成
   */
  useEffect(() => {
    if (!topAnimLottie) {
      const lottieObj = createLottie()
      setTopAnimLottie(lottieObj)

      lottieObj.addEventListener('data_ready', () => {
        playLottie(topState, lottieObj)
      })
    }

    return () => {
      if(topAnimLottie) {
        topAnimLottie.destroy()
      }
    }
  }, [topAnimLottie])

  /**
   * TopStateの変更を検知して再生を実行
   */
  useEffect(() => {
    if(!!topAnimLottie) {
      playLottie()
    }
  },[topState])

  return (<div>
    <div id="topAnimation" className={styles.lottie}/>
    <Noise />
  </div>)
}

export default TopAnimation
