import styles from "./ConfirmLocalStorageModal.module.scss"
import Button from "../../ui/buttons/Button";
import Modal from "../../ui/modals/Modal";
import JsonData from "../../../data/JsonData";
import {Sanitize} from "../../../utils/common";
import {useRef} from "react";
import PortalModal from "../../ui/modals/PortalModal";

function ConfirmLocalStorageModal({open, onClose}) {
  const TextData = JsonData.textData
  // Functions
  const onClick = () => {
    onClose()
  }

  const childRef = useRef()
  const hide = () => {
    childRef.current.hide()
  }
  return <PortalModal open={open}>
    <Modal ref={childRef} open={open} onClose={onClick} hideClose={true}>
      <div className={styles.wrapper}>
        <div className={styles.flexTop}>
          <div>
            <p className={styles.title} dangerouslySetInnerHTML={{__html: Sanitize(TextData.localStorage.title)}}/>
            <p className={styles.text} dangerouslySetInnerHTML={{__html: Sanitize(TextData.localStorage.text)}}/>
            <p className={styles.note} dangerouslySetInnerHTML={{__html: Sanitize(TextData.localStorage.note)}}/>
          </div>
        </div>
        <div className={styles.flexBottom}>
          <Button onClick={hide} color={"brown"} seName='button'>{TextData.common.ok}</Button>
        </div>
      </div>
    </Modal>
  </PortalModal>
}

export default ConfirmLocalStorageModal
