// import {useNavigate} from "react-router-dom"
import styles from "./StoryResultMedals.module.scss"
import React, {useEffect, useRef, useState} from "react"
import JsonData from "../../../data/JsonData"
import {useLocalStorageContext} from "../../../contexts/LocalStorageContext"
import {Sanitize} from "../../../utils/common";
import gsap from "gsap";
import {useModalContext} from "../../../contexts/ModalContext";
import {useBudouX} from "../../../hooks/useBudouX";
import Button from "../../ui/buttons/Button";

function StoryResultMedals({storyId}) {
  const { parse } = useBudouX()

  /** react-router-dom系 **/
  // const navigate = useNavigate()

  /** JSONデータ **/
  // テキストデータ
  const TextData = JsonData.textData
  // メダルデーター
  const MedalData = JsonData.medalData
  // ストーリーデータ
  const StoryData = JsonData.storyListData.filter(d => d.id === storyId)[0]
  const medals = StoryData.medals.split(",")

  /** States **/
  const [medalCount, setMedalCount] = useState(0)

  /** Context **/
  const {
    userStoryData,
  } = useLocalStorageContext()
  const {
    showMedalModal,
    showMedalDetailModal
  } = useModalContext()

  /** Refs **/
  const innerRef = useRef()
  const medalZeroRef = useRef()
  const medalRefs = useRef([])
  const goToMedalRef = useRef()

  /**
   * アニメーション
   */
  const timeline = gsap.timeline({paused: true})
  useEffect(() => {

    // StoryResult.jsのアニメーションを待ち時間
    const baseDelay = 2.4

    // 白背景
    timeline.fromTo(innerRef.current, {
      alpha:0
    }, {
      alpha: 1,
      duration: 0.6,
      delay: baseDelay
    })

    // 内容表示
    if(medalZeroRef.current) {
      // メダルゼロの場合
      timeline.fromTo(medalZeroRef.current, {
        alpha: 0
      }, {
        alpha: 1,
        duration: .6
      })

    } else {
      // メダルがある場合
      let countUp= 0
      timeline.fromTo(medalRefs.current, {
        alpha:0,
        y:20,
      }, {
        alpha: 1,
        y: 0,
        duration: 0.6,
        stagger: {
          each:0.3,
          onStart: () => {
            countUp++
            setMedalCount(countUp)
          }
        },
      })
    }
    timeline.fromTo(goToMedalRef.current, {
      alpha:0
    }, {
      alpha: 1,
      duration: 0.6
    }, "+=0.8")

    timeline.play()
  }, [])


  return <div className={styles.wrapper}>
    <div ref={innerRef} className={styles.inner}>
      <div className={styles.title}>
        <p>{TextData.result.medalCount}{medalCount}</p>
      </div>

      {(userStoryData.medals.length === 0) ?

        // メダルゼロ
        <p ref={medalZeroRef} className={styles.medalZero} dangerouslySetInnerHTML={{__html: Sanitize(TextData.result.medalZero)}}/> :

        // メダル獲得
        <ul className={styles.list}>
          {medals.map((medalId) => {
            if (userStoryData.medals && userStoryData.medals.includes(medalId)) {
              const data = MedalData.find(d => d.id === medalId)
              const zeroPadId = data.id.padStart(2, '0')
              return <li
                ref={el => {medalRefs.current.push(el)}}
                className={styles.badge} key={data.id}
              >
                <button className={styles.link} onClick={() => showMedalDetailModal(data.id)}>
                  <img className={styles.shadow} src={`/assets/img/medals/${zeroPadId}.png`} alt={data.titleFlat}/>
                  <p className={styles.badgeTitle} dangerouslySetInnerHTML={{__html: parse(Sanitize(data.titleFlat))}}/>
                </button>
              </li>
            } else {
              return null
            }
          })}
        </ul>
      }

      <div ref={goToMedalRef} className={styles.goToMedals}>
        <Button onClick={showMedalModal}>メダルずかんをみる</Button>
      </div>
    </div>
  </div>
}

export default StoryResultMedals
