import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import styles from "./CompleteMedalModal.module.scss"
import Modal from "../../ui/modals/Modal";
import {useLocalStorageContext} from "../../../contexts/LocalStorageContext";
import {useLocalStorage} from "../../../hooks/useLocalStorage";
import {LocalStorageKeys} from "../../../constants/AppConstant";
import JsonData from "../../../data/JsonData";
import PortalModal from "../../ui/modals/PortalModal";
import Button from "../../ui/buttons/Button";
import RotationBackground from "../Common/RotationBackground";
import {useModalContext} from "../../../contexts/ModalContext";
import gsap from "gsap"

function CompleteMedalModal(props, ref) {
  /** JSONデータ **/
  const MedalData = JsonData.medalData

  /** ローカルストレージ **/
  const {generalMedal} = useLocalStorageContext()

  /** Contexts **/
  const {
    showMedalModal
  } = useModalContext()

  /** States **/
  const [showModalFlg, setShowModalFlg] = useState(false)
  const [showedMedalComplete, setShowedMedalComplete] = useLocalStorage(LocalStorageKeys.ShowedMedalComplete)

  const showMedalCompleteFlg =
    // まだメダルコンプリートモーダルを表示したことがなく
    !showedMedalComplete &&
    // ローカルストレージのGeneralMedalsにMedalsが存在していて
    !!generalMedal.medals &&
    // メダルの総数と、取得したメダルの数が一緒であれば、表示する
    JsonData.medalData.length === generalMedal.medals.length

  /** Functions **/
  const closeModal = () => {
    setShowModalFlg(false)
  }

  /** Hide **/
  const childRef = useRef()
  const hide = () => {
    childRef.current.hide()
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      if (showMedalCompleteFlg) {
        // モーダル表示
        setShowModalFlg(true)
        // モーダル表示したことをローカルストレージに記録
        setShowedMedalComplete(true)
      }
    }
  }), [])

  /** Animation **/
  const titleRef = useRef()
  const explainRef = useRef()
  const medalListRefs = useRef([])
  const buttonRefs = useRef([])
  let timeline = gsap.timeline({paused: true})

  useEffect(() => {
    if(showModalFlg) {
      timeline.fromTo([titleRef.current, explainRef.current], {
        y: 30,
        opacity: 0
      }, {
        y: 0,
        opacity: 1,
        duration: 0.5,
        delay: 1,
        stagger: 0.2,
      })
      console.log(medalListRefs.current);
      timeline.fromTo(medalListRefs.current, {
        scale: 0
      }, {
        scale: 1,
        duration: 0.5,
        stagger: 0.1,
        ease: "elastic.out(1, 0.75)"
      })
      timeline.fromTo(buttonRefs.current, {
        alpha: 0,
        y: 10,
      }, {
        alpha: 1,
        y: 0,
        duration: 1,
        stagger: 0.3,
      }, "+=0.3")
      timeline.play()
    }
  }, [showModalFlg])

  return <PortalModal open={showModalFlg}>
    <Modal ref={childRef} open={showModalFlg} onClose={closeModal} hideClose={true}>
      <div className={styles.wrapper}>
        <RotationBackground />
        <div className={styles.inner}>
          <p ref={titleRef} className={styles.title}>すべてのメダルをゲット！</p>
          <p ref={explainRef} className={styles.explain}>
            17のメダルをすべてあつめたよ！<br />
            おめでとう！
          </p>
          <div className={styles.medalList}>
            <ul>
              {MedalData.map(data => {
                const zeroPadId = data.id.padStart(2, '0')
                return <li key={data.id} ref={(el) => {medalListRefs.current.push(el)}}>
                  <img className={styles.shadow} src={`/assets/img/medals/${zeroPadId}.png`} alt={data.title}/>
                </li>
              })}
            </ul>
          </div>
          <ul className={styles.buttonWrap}>
            <li ref={(el) => {buttonRefs.current.push(el)}}><Button onClick={showMedalModal}>メダルずかんをみる</Button></li>
            <li ref={(el) => {buttonRefs.current.push(el)}}><Button onClick={hide} color={"brown"}>とじる</Button></li>
          </ul>
        </div>
      </div>
    </Modal>
  </PortalModal>
}

export default forwardRef(CompleteMedalModal)
