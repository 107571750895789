import styles from "./ActiveMedal.module.scss"
import inactiveImage from "../../../img/medals/inactive.png"

function InactiveMedal({data}) {
  return (<div className={styles.card}>
    <div className={styles.inner}>
      <p className={styles.id}>{data.id.padStart(2, '0')}</p>
      <div className={styles.medal}>
        <img src={inactiveImage} alt=""/>
      </div>
      <p className={styles.title}>？？？</p>
    </div>
  </div>)
}

export default InactiveMedal
