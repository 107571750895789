import { loadDefaultJapaneseParser } from 'budoux'
const parser = loadDefaultJapaneseParser()

export const useBudouX = () => {
  const parse = (text, threshold) => {
    return parser.translateHTMLString(text, threshold)
  }
  return {
    parse
  }
}
