import styles from "./StoryList.module.scss"
import JsonData from "../../../data/JsonData";
import {Link} from "react-router-dom";
import {GeneralMedalsLocalStorage} from "../../../storages/GeneralMedalsLocalStorage";
import IconMomotaro from "../../../img/top/icon_momotaro.png"
import IconPigs from "../../../img/top/icon_pigs.png"
import IconAnts from "../../../img/top/icon_ants.png"
import Audio from "../../../audios/Audio";
import Noise from "../../partsSmall/common/Noise";
// import Picture from "../../partsSmall/image/Picture";
// import textImg from "../../../img/top/text.png";
// import textImgSp from "../../../img/top/text_sp.png";

const Icons = [IconMomotaro, IconPigs, IconAnts]

function StoryList() {
  /** JSONデータ **/
  // テキストデータ
  const TextData = JsonData.textData
  const storyListData = JsonData.storyListData

  /** ローカルストレージ **/
  const {generalMedal} = GeneralMedalsLocalStorage()

  /************************
   * Functions
   ************************/

  /**
   * ストーリーで獲得できるメダルと、獲得しているメダル状況を照らし合わせてBooleanの配列を作成
   */
  const createMedalStateArray = storyMedals => {
    // ストーリーで獲得できるメダルの情報を、配列へ変換する。
    // 15,14,16,6,4,17 → ['15', '14', '16', '6', '4', '17']
    const storyMedalArray = storyMedals.split(',')

    // メダル獲得カウンター
    let activeCount = 0

    // メダル獲得状況を入れる変数
    const stateArray = []

    storyMedalArray.forEach(medalId => {
      // falseをとりあえず打ち込む
      stateArray.push(false)

      if (!!generalMedal.medals && generalMedal.medals.includes(medalId)) {
        // メダルを獲得してたらactiveCount目にtrueを打ち込む （左から順番にアクティブになるためactiveCount目に打ち込んでる）
        stateArray[activeCount] = true

        // メダル獲得カウンターをインクリメント
        activeCount++
      }
    })
    return stateArray
  }

  return <div className={styles.wrapper}>
    <div className={styles.content}>
      <div>

        <div className={styles.text}>
          <svg className="show-pc" width="538" height="93" viewBox="0 0 538 93" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M269 93L255.144 69L282.856 69L269 93Z" fill="white"/>
            <rect width="538" height="72" rx="36" fill="white"/>
          </svg>
          <svg className="show-sp" width="301" height="61" viewBox="0 0 301 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M150 61L142.206 47.5L157.794 47.5L150 61Z" fill="white"/>
            <rect width="301" height="48" rx="24" fill="white"/>
          </svg>
          <p>{TextData.top.title}</p>
        </div>

        <ul className={styles.selection}>
          {storyListData.map((d, index) => {
            const medalStateArray = createMedalStateArray(d.medals)

            return <li key={d.id} className={styles.list}>
              <Link className={styles.link} to={`/s/${d.id}/`} onClick={() => {
                Audio.playSE('selectStory')
              }}>

                <div className={styles.illust}>
                  <div className={styles.image} >
                    <img src={Icons[index]} alt={d.title}/>
                    <Noise/>
                  </div>
                </div>

                <div className={styles.medalState}>
                  {medalStateArray.map((bool, medalIndex) => {
                    const MedalClassName = (bool) => {
                      return (bool) ? styles.medalActive : styles.medalInactive
                    }
                    return <div key={d.id + medalIndex} className={styles.medal}>
                      <svg className={MedalClassName(bool)} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_883_1051)">
                          <path d="M2.99952 17.4001L0.327148 21.0437L4.0618 21.1634L5.3178 24.6674L7.90333 21.1368C5.92713 20.4126 4.21753 19.1099 2.99952 17.4001Z" fill="#DEDEDE"/>
                          <path d="M5.32468 25H5.2779C5.21797 24.9899 5.16175 24.9643 5.11486 24.9258C5.06796 24.8873 5.03203 24.8372 5.01067 24.7805L3.82816 21.5159L0.320675 21.4029C0.260326 21.4008 0.201652 21.3825 0.150919 21.3499C0.100187 21.3173 0.059294 21.2717 0.032564 21.2178C0.00583399 21.1639 -0.00572515 21.1038 -0.000873239 21.0439C0.00397867 20.984 0.0250509 20.9265 0.0601089 20.8776L2.73248 17.234C2.76126 17.1883 2.8019 17.1512 2.85012 17.1267C2.89833 17.1021 2.95233 17.0909 3.0064 17.0944C3.05893 17.0946 3.11064 17.1074 3.15715 17.1317C3.20366 17.156 3.24361 17.1911 3.27363 17.234C4.45015 18.8928 6.10736 20.1547 8.02377 20.851C8.07294 20.8693 8.11721 20.8986 8.15314 20.9366C8.18906 20.9747 8.21566 21.0205 8.23088 21.0705C8.24687 21.1194 8.25096 21.1715 8.24283 21.2223C8.2347 21.2732 8.21458 21.3214 8.18411 21.363L5.5986 24.8404C5.57078 24.8884 5.5309 24.9283 5.48287 24.9562C5.43484 24.9842 5.38032 24.9993 5.32468 25ZM0.975392 20.7314L4.08204 20.8311C4.14815 20.8346 4.21179 20.8573 4.26519 20.8962C4.31858 20.9352 4.35939 20.9888 4.38266 21.0505L5.42489 23.9627L7.38908 21.3031C5.6593 20.5988 4.14734 19.4519 3.0064 17.9787L0.975392 20.7314Z" fill="#DEDEDE"/>
                          <path d="M21.9935 17.4001L24.6659 21.0437L20.9245 21.1634L19.6685 24.6674L17.083 21.1368C19.0617 20.4138 20.7737 19.111 21.9935 17.4001Z" fill="#DEDEDE"/>
                          <path d="M19.6688 25.0001C19.6168 25.0012 19.5652 24.9896 19.5187 24.9665C19.4721 24.9433 19.4319 24.9092 19.4016 24.8671L16.8094 21.3365C16.7789 21.2949 16.7588 21.2467 16.7507 21.1958C16.7425 21.145 16.7466 21.0929 16.7626 21.044C16.7804 21.0006 16.8067 20.9612 16.84 20.9281C16.8732 20.895 16.9128 20.8689 16.9564 20.8511C18.8746 20.1582 20.5328 18.8956 21.7065 17.2341C21.7759 17.1537 21.8743 17.1036 21.9804 17.0945C22.0329 17.0947 22.0846 17.1075 22.1311 17.1318C22.1777 17.1561 22.2176 17.1912 22.2476 17.2341L24.92 20.8777C24.9576 20.926 24.9808 20.9839 24.9869 21.0446C24.993 21.1054 24.9819 21.1666 24.9547 21.2214C24.9276 21.2762 24.8855 21.3223 24.8333 21.3544C24.7811 21.3865 24.7208 21.4033 24.6595 21.403L21.152 21.516L19.9695 24.8073C19.9487 24.8643 19.9129 24.9147 19.8659 24.9533C19.8189 24.9919 19.7624 25.0172 19.7022 25.0267L19.6688 25.0001ZM17.6244 21.29L19.5819 23.9495L20.6242 21.0373C20.6474 20.9756 20.6883 20.922 20.7417 20.883C20.7951 20.8441 20.8587 20.8215 20.9248 20.8179L24.0314 20.7182L22.0272 17.9522C20.8795 19.4302 19.361 20.5813 17.6244 21.29Z" fill="#DEDEDE"/>
                          <path d="M12.4998 15.9043C15.6066 15.9043 18.1252 13.3978 18.1252 10.3059C18.1252 7.21401 15.6066 4.70752 12.4998 4.70752C9.39306 4.70752 6.87451 7.21401 6.87451 10.3059C6.87451 13.3978 9.39306 15.9043 12.4998 15.9043Z" fill="#DEDEDE"/>
                          <path d="M12.5001 2.14451e-06C10.4504 -0.00131307 8.44636 0.602363 6.74144 1.73472C5.03652 2.86707 3.70734 4.47721 2.92203 6.36146C2.13673 8.2457 1.93058 10.3194 2.32965 12.3203C2.72871 14.3212 3.71508 16.1593 5.16398 17.6022C6.61288 19.0451 8.45921 20.0279 10.4694 20.4264C12.4797 20.8248 14.5635 20.621 16.4573 19.8407C18.3511 19.0603 19.9699 17.7385 21.1088 16.0425C22.2477 14.3465 22.8556 12.3524 22.8556 10.3125C22.8564 8.95857 22.5892 7.6177 22.0692 6.36657C21.5492 5.11544 20.7866 3.97853 19.8249 3.02084C18.8632 2.06315 17.7214 1.30343 16.4645 0.785099C15.2077 0.266766 13.8606 1.86273e-06 12.5001 2.14451e-06ZM12.5001 17.1742C11.1346 17.1755 9.79936 16.7737 8.66341 16.0195C7.52746 15.2653 6.64185 14.1926 6.11867 12.9373C5.5955 11.682 5.45828 10.3005 5.72436 8.96755C5.99044 7.6346 6.64786 6.41013 7.61344 5.44918C8.57902 4.48822 9.80933 3.83394 11.1487 3.56914C12.4881 3.30433 13.8763 3.44093 15.1376 3.96161C16.3989 4.48228 17.4767 5.36365 18.2345 6.49416C18.9924 7.62467 19.3962 8.95351 19.3948 10.3125C19.3931 12.1318 18.6661 13.8761 17.3735 15.1625C16.0809 16.4489 14.3282 17.1724 12.5001 17.1742Z" fill="#DEDEDE"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_883_1051">
                            <rect width="25" height="25" fill="white"/>O
                          </clipPath>
                        </defs>
                      </svg>

                    </div>
                  })}
                </div>
              </Link>
            </li>
          })}
        </ul>
      </div>
    </div>
  </div>
}

export default StoryList
