import styles from "./Menu.module.scss"
import JsonData from "../../../data/JsonData";
import ModalBase from "../../ui/modals/ModalBase";
import CloseButton from "../../ui/buttons/CloseButton";
import {useRef, useState} from "react";
import {ModalZIndex} from "../../../constants/AppConstant";
import {Link} from "react-router-dom";
import AddHomeIconModal from "../Top/AddHomeIconModal";
import Audio from "../../../audios/Audio";
import {useModalContext} from "../../../contexts/ModalContext";

function Menu({open, onClose}) {
  /** JSONデータ **/
  const TextData = JsonData.textData

  /** States **/
  const [showHomeIconModal, setShowHomeIconModal] = useState(false)

  /** Contexts **/
  const {
    showAboutModal,
    showHowToModal,
    showMedalModal,
  } = useModalContext()

  /** SHAREデータ **/
  const url = encodeURIComponent(TextData.common.url)
  const shareTwt = `https://twitter.com/intent/tweet?text=${encodeURIComponent(TextData.share.twtText)}&url=${url}&hashtags=${encodeURIComponent(TextData.share.hashtags)}`
  const shareFb = `https://www.facebook.com/sharer/sharer.php?u=${url}`

  const parent = useRef()
  const hide = () => {
    Audio.playSE('menuClose')
    parent.current.hide()
  }

  return (<>
    <ModalBase
      ref={parent}
      open={open}
      onClose={onClose}
      // (モーダルオンモーダル)メニューの上に更にモーダルが表示されているかの状態
      isMOM={showHomeIconModal}
      // モーダルオンモーダルが起こるので、1つzIndexを下げている
      zIndex={ModalZIndex.menu}
    >
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div>
            <ul className={styles.mainMenu}>
              <li>
                <button onClick={showAboutModal}>{TextData.globalNav.about}</button>
              </li>
              <li>
                <button onClick={showHowToModal}>{TextData.globalNav.howto}</button>
              </li>
              <li>
                <button onClick={showMedalModal}>{TextData.globalNav.medals}</button>
              </li>
              <li>
                <a href="/educator/" target="_blank" rel="noreferrer" className={styles.external}>{TextData.globalNav.example}</a>
              </li>
            </ul>

            <button className={styles.homeIcon} onClick={() => {setShowHomeIconModal(true)}}>
              <p className={styles.top}>{TextData.globalNav.homeIconSpTop}</p>
              <p className={styles.bottom}>{TextData.globalNav.homeIconSpBottom}</p>
            </button>

            <div className={styles.shareMenu}>
              {/*<p className={"show-pc"}>{TextData.globalNav.share}</p>*/}
              <ul>
                <li className={styles.isTwt}>
                  <a href={shareTwt} target="_blank" rel="noreferrer">
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M41.6563 13.9332C43.5238 12.8167 44.9212 11.0587 45.5875 8.9874C43.8326 10.0287 41.9126 10.7621 39.9104 11.1561C37.1346 8.21974 32.7365 7.50516 29.1738 9.41168C25.611 11.3182 23.7657 15.3738 24.6688 19.3124C17.4805 18.9515 10.7833 15.556 6.24378 9.97073C3.8747 14.057 5.08535 19.2807 9.01045 21.9082C7.59109 21.8626 6.20321 21.4783 4.96253 20.7874C4.96253 20.8249 4.96253 20.8624 4.96253 20.8999C4.96335 25.1565 7.96331 28.823 12.1354 29.6666C10.8189 30.0247 9.43796 30.0775 8.09795 29.8207C9.27128 33.4609 12.6262 35.9547 16.45 36.0291C13.283 38.5147 9.37186 39.8627 5.34587 39.8561C4.63226 39.8572 3.91922 39.8161 3.21045 39.7332C7.29876 42.3604 12.0571 43.7548 16.9167 43.7499C23.6776 43.7963 30.1751 41.1309 34.9557 36.3499C39.7363 31.5689 42.4012 25.0712 42.3542 18.3103C42.3542 17.9228 42.3452 17.5374 42.3271 17.1541C44.0781 15.8886 45.5892 14.321 46.7896 12.5249C45.1584 13.2479 43.4281 13.7227 41.6563 13.9332Z"
                        fill="#26497D"/>
                    </svg>
                  </a>
                </li>
                <li className={styles.isFb}>
                  <a href={shareFb} target="_blank" rel="noreferrer">
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4.1709 25.0041C4.17332 35.2528 11.6259 43.9791 21.748 45.5853V31.0249H16.4626V25.0041H21.7542V20.4207C21.5177 18.2489 22.2595 16.0845 23.7785 14.5143C25.2975 12.9442 27.4362 12.1312 29.6146 12.2957C31.1783 12.321 32.738 12.4602 34.2813 12.7124V17.8353H31.648C30.7414 17.7166 29.83 18.016 29.1704 18.6492C28.5109 19.2824 28.1746 20.1809 28.2563 21.0916V25.0041H34.0292L33.1063 31.027H28.2563V45.5853C39.2031 43.8553 46.8794 33.8578 45.724 22.8355C44.5687 11.8132 34.9861 3.62474 23.9184 4.20234C12.8508 4.77995 4.17266 13.9214 4.1709 25.0041Z"
                        fill="#26497D"/>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>

            {/*<button className={styles.homeIcon} onClick={() => {setShowHomeIconModal(true)}}>{TextData.globalNav.homeIcon}</button>*/}

            <ul className={styles.companyMenu}>
              <li>
                <Link to="/policy/">{TextData.globalNav.policy}</Link>
              </li>
              <li><a className={styles.external} href="https://docs.google.com/forms/d/e/1FAIpQLSc_C8JHvB_39rEI5jbP-C4lAECr4iPduZOWNo9l419keSnQJQ/viewform?usp=sf_link" target="_blank" rel="noreferrer">{TextData.globalNav.contact}</a></li>
              <li><a className={styles.external} href="https://iandco.com/ja" target="_blank" rel="noreferrer">{TextData.globalNav.company}</a></li>
            </ul>
          </div>
        </div>
      </div>
      {onClose && <div className={styles.closeButton}>
        <CloseButton onClose={hide}/>
      </div>}
    </ModalBase>

    <AddHomeIconModal open={showHomeIconModal} onClose={() => {
      setShowHomeIconModal(false)
    }}/>
  </>)
}

export default Menu
