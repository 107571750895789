import {useNavigate} from "react-router-dom"
import styles from "./StoryResult.module.scss"
import Button from "../../ui/buttons/Button"
import React, {useEffect, useRef} from "react"
import JsonData from "../../../data/JsonData"
import {useLocalStorageContext} from "../../../contexts/LocalStorageContext"
import StoryPictures from "./StoryPictures"
import {Sanitize} from "../../../utils/common"
import StoryResultMedals from "./StoryResultMedals";
import gsap from "gsap"

function StoryResult({sceneData, storyId}) {
  /** react-router-dom系 **/
  const navigate = useNavigate()

  /** JSONデータ **/
  // テキストデータ
  const TextData = JsonData.textData

  /** Context **/
  const {
    userStoryData,
    editUserStoryMedalData
  } = useLocalStorageContext()

  /** Refs **/
  const titleRef = useRef()
  const textRef = useRef()
  const imageRef = useRef()

  /** useState **/
  let lastSceneId = userStoryData?.prev
  if(!!lastSceneId) {
    lastSceneId = lastSceneId.split("__")[0]
  }

  /** gsap **/
  const timeline = gsap.timeline({paused: true})
  useEffect(() => {
    timeline.fromTo([titleRef.current, textRef.current, imageRef.current], {
      alpha:0,
      y:20,
    }, {
      alpha: 1,
      y: 0,
      duration: 1,
      delay: 0.3,
      power: "power2.out",
      stagger: 0.4
    })
    // timeline.fromTo(textRef.current, {
    //   alpha:0,
    //   y:20,
    // }, {
    //   alpha: 1,
    //   y: 0,
    //   duration: 0.6,
    // }, "=-0.3")

    timeline.play()
  }, [])

  /** Functions **/

  const onMore = () => {
    editUserStoryMedalData("delete")
    navigate(`/s/${storyId}/`)
  }

  const toTop = () => {
    editUserStoryMedalData("delete")
    window.location.href = `/top/`
  }

  console.log(lastSceneId);

  return (userStoryData) ?
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.content}>
          <div>
            <p ref={titleRef} className={styles.title}>{TextData.result.title}</p>

            {sceneData && <p ref={textRef} className={styles.text} dangerouslySetInnerHTML={{__html: Sanitize(sceneData.text)}}/>}

            {lastSceneId && <div ref={imageRef} className={styles.image}>
              <StoryPictures storyId={storyId} sceneId={lastSceneId} index={9999}/>
            </div>}

            <StoryResultMedals storyId={storyId} />

            <ul className={styles.buttons}>
              <li><Button onClick={onMore} seName="button">{TextData.result.again}</Button></li>
              <li><Button onClick={toTop} seName="button">{TextData.result.another}</Button></li>
            </ul>
          </div>
        </div>
      </div>
    </div>:
    null
}

export default StoryResult
