import ModalProvider from "../../../contexts/ModalContext";
import TopStateProvider from "../../../contexts/TopStateContext";
import TopManager from "../../../managers/TopManager";

function Top() {
  return (<ModalProvider>
    <TopStateProvider>
      <TopManager/>
    </TopStateProvider>
  </ModalProvider>)
}

export default Top
