import styles from "./ModalBase.module.scss"
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import gsap from "gsap";
import { useKey } from "rooks"; // useKeyをimport

const focusableElementsString = 'a[href], area[href], input:not([disabled]), button:not([disabled]), object, embed, [tabindex="0"], [contenteditable]'


function ModalBase(
  {
    // 表示フラグ
    open,
    // モーダルonモーダルの場合、trapTabKeyを制御するためのフラグ
    isMOM = false,
    // クローズ関数
    onClose = null,
    // zIndexの設定が必要な場合の関数
    zIndex = null,
    //
    children,
  }, ref) {

  /** アクセシビリティ用変数 **/
  const [focusableElements, setFocusableElements] = useState(null)
  const [focusedElementBeforeModal, setFocusedElementBeforeModal] = useState(null)
  const [firstFocusableEl, setFirstFocusableEl] = useState(null)
  const [lastFocusableEl, setLastFocusableEl] = useState(null)

  /** Refs **/
  const wrapperRef = React.createRef()

  useEffect(() => {
    if(zIndex) wrapperRef.current.style.zIndex = zIndex
  }, [])

  useEffect(() => {
    if(open) {
      show()
    }
  }, [open])

  const hide = () => {
    // アクセシビリティ
    if(focusedElementBeforeModal) focusedElementBeforeModal.focus()

    // 非表示
    wrapperRef.current.style.pointerEvents = ''

    gsap.to(wrapperRef.current, {
      opacity: 0,
      duration: 0.2,
      onComplete: () => {
        if(wrapperRef) wrapperRef.current.style.visibility = 'hidden'
        if(onClose) onClose()
      }
    })
  }
  useImperativeHandle(ref, () => ({
    hide
  }), [focusedElementBeforeModal, wrapperRef, onClose])

  // const trapTabKey = (e) => {
  //   if (e.keyCode === 27) {
  //     if(onClose) onClose()
  //   }
  // }

  useKey(["Tab"], (e) => {
    if(!focusableElements || !!isMOM || !open) return
    if(e.shiftKey) {
      if(document.activeElement === firstFocusableEl) {
        e.preventDefault()
        lastFocusableEl.focus()
      }
    } else {
      if(document.activeElement === lastFocusableEl) {
        e.preventDefault()
        firstFocusableEl.focus()
      }
    }
  });
  useKey(["Escape"], () => {
    if(!focusableElements || !!isMOM || !open) return
    hide()
  });

  const show = () => {
    // アクセシビリティ
    setFocusedElementBeforeModal(document.activeElement)
    const _focusableEls = (focusableElements) ? focusableElements : wrapperRef.current.querySelectorAll(focusableElementsString)
    if (!focusableElements) {
      setFocusableElements(_focusableEls)
      setFirstFocusableEl(_focusableEls[0])
      setLastFocusableEl(_focusableEls[_focusableEls.length - 1])
    }
    setTimeout(() => {
      _focusableEls[0].focus({
        preventScroll: true
      })
    },10)

    // 表示
    wrapperRef.current.style.pointerEvents = 'visible'
    wrapperRef.current.style.visibility = 'visible'

    gsap.to(wrapperRef.current, {
      opacity: 1,
      duration: 0.2
    })
  }


  return(<div
    ref={wrapperRef}
    className={styles.wrapper}
    role="dialog"
    aria-hidden={!open}
    aria-modal
  >
    {children}
  </div>)
}

export default forwardRef(ModalBase)
