import dompurify from 'dompurify';
const sanitizer = dompurify.sanitize;

export const ReplaceBR = str => {
  return str.replace(/\n/g, '<br />')
}

export const CompareFlagAndLocalStorage = (flagAry, LocalStorageArray) => {
  let count = 0
  flagAry.forEach(flag => {
    if(LocalStorageArray.includes(flag)) count++
  })
  return flagAry.length === count
}

export const GetStorageValue = (key, isLocalStorage = true) => {
  const isClient = typeof window !== 'undefined'
  if(isClient) {
    const saved = (isLocalStorage) ? localStorage.getItem(key) : sessionStorage.getItem(key)
    return (saved) ? JSON.parse(saved) : ''
  } else {
    return ''
  }
}

export const SetStorageValue = (key, value, isLocalStorage = true) => {
  const isClient = typeof window !== 'undefined'
  if(isClient) {
    if (isLocalStorage) {
      localStorage.setItem(key, JSON.stringify(value))
    } else {
      sessionStorage.setItem(key, JSON.stringify(value))
    }
  }
}

export const GetQueriesBySearch = str => {
  if(!str) return
  const step1 = str.slice(1)
  const step2 = step1.split('&')
  let obj = {}
  step2.forEach(query => {
    const split = query.split('=')
    obj[split[0]] = split[1]
  })
  return obj
}

export const Sanitize = (html) => {
  return sanitizer(html)
}
