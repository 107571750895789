import styles from "./StorySelect.module.scss"
import StoryPictures from "./StoryPictures";
import {Link} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import gsap from "gsap"
import DebugConstants from "../../../constants/DebugConstants";
import {useBudouX} from "../../../hooks/useBudouX";
import Audio from "../../../audios/Audio";
import {Sanitize} from "../../../utils/common";

const killButtonFadeIn = DebugConstants.getValues('Story', 'killButtonFadeIn')

function StorySelect({storyId, sceneId, select, setPageTransition}) {
  const { parse } = useBudouX()

  const selectAId = sceneId
  const selectBId = sceneId.slice(0, sceneId.length -2) + "_b"

  const SelectData = [
    {
      id: "A",
      selectId: selectAId
    },
    {
      id: "B",
      selectId: selectBId
    }
  ]


  /** useState **/
  // つぎへなどのボタンがダブルクリックされないように
  const [preventDoubleClick, setPreventDoubleClick] = useState(false)

  /**
   * ボタンのフェードイン
   */
  const buttonEls = useRef([])
  useEffect(() => {
    if(buttonEls.current.length === 0) return

    buttonEls.current.forEach(el => {
      el.style.pointerEvents = 'none'
    })

    const delay = killButtonFadeIn ? 0 : 1
    const gsapObj = gsap.fromTo(buttonEls.current, {
      opacity: 0
    }, {
      opacity: 1,
      duration: 0.6,
      delay,
      onComplete: () => {
        buttonEls.current.forEach(el => {
          el.style.pointerEvents = 'visible'
        })
      }
    })
    return () => {
      if(gsapObj) gsapObj.kill()
    }


  }, [buttonEls])

  /**
   * ボタンの文字が少ないときは [A.123      ] ではなく [   A.123   ] とセンタリングしたいとのことだが、
   * `useBudouX`を使用していると、文字数が多い場合にどうしても右に余白ができるので、
   * ↓ 理想         ↓ 現実
   * ----------   ----------
   *    1234      1234
   *    1234      1234
   * ----------   ----------
   * なので、`useBudouX`で作成されたspanの要素のwidthを取得して、ddにwidthを指定して、無理やり真ん中に
   */
  useEffect(() => {
    if(buttonEls.current.length <= 0) return
    buttonEls.current.forEach(el => {
      const spanEl = el.querySelector('span')
      const selectTextEl = el.querySelector('dd')
      selectTextEl.style.width = spanEl.getBoundingClientRect().width + 'px'
    })
  }, [])

  return (<main className={styles.wrapper}>
    {SelectData.map((d, index) => {
      return <div key={d.id} className={styles.box}>
        <StoryPictures storyId={storyId} sceneId={d.selectId}/>
        <div className={styles.linkWrap}>
          <Link
            ref={el => buttonEls.current[index] = el}
            className={styles.link}
            to={`/s/${storyId}/${select[d.id].next}`}
            onClick={() => {
              if(preventDoubleClick) return
              setPreventDoubleClick(true)
              setPageTransition(true)
              Audio.playSE('nextPage')
            }
          }>
            <dl className={styles.linkInner}>
              <dt className={styles.id}>{d.id}</dt>
              <dd className={styles.text} dangerouslySetInnerHTML={{__html: Sanitize(parse(select[d.id].text, 1000))}} />
            </dl>
          </Link>
        </div>
      </div>
    })}
  </main>)
}

export default StorySelect
