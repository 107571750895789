import {memo} from 'react';
import styles from "./StoryPictures.module.scss"
import JsonData from "../../../data/JsonData";
import PictureImage from "./StoryPictures/PictureImage"
import LottieImage from "./StoryPictures/LottieImage";
import Noise from "../../partsSmall/common/Noise";

// 拡張子
const ExtType = {
  "image": "image",
  "lottie": "lottie"
}

const ExtList = {
  [ExtType.image]: ['svg', 'png'],
  [ExtType.lottie]: ['json']
}

function StoryPictures({storyId, sceneId, index = 0}) {
  // 背景画像
  const {hasFile: hasBgFile, fileSrc: bgFileSrc} = CheckImage(storyId, `${sceneId}-bg`, ExtType.image)

  // メイン画像
  const {hasFile: hasMainFile, fileSrc: mainFileSrc} = CheckImage(storyId, `${sceneId}-main`, ExtType.image)
  const mainPosition = CheckPosition(mainFileSrc)

  // メインLottie
  const {hasFile: hasMainLottieFile, fileSrc: mainLottieFileSrc} = CheckImage(storyId, `${sceneId}-main`, ExtType.lottie)
  const mainLottiePosition = hasMainLottieFile && CheckPosition(mainLottieFileSrc)

  // サブ画像
  const {hasFile: hasSubFile, fileSrc: subFileSrc} = CheckImage(storyId, `${sceneId}-sub`, ExtType.image)
  const subPosition = CheckPosition(subFileSrc)

  // サブLottie
  const {hasFile: hasSubLottieFile, fileSrc: subLottieFileSrc} = CheckImage(storyId, `${sceneId}-sub`, ExtType.lottie)
  const subLottiePosition = hasSubLottieFile && CheckPosition(subLottieFileSrc)

  const getClassName = (base, position) => {
    return `${base} ${styles.imageWrap} ${position.isUnder ? styles.positionUnder : ''}  ${position.isUpper ? styles.positionUpper : ''}`
  }

  return (
    <div className={styles.wrapper}>

      {/* BG */}
      {hasBgFile && <div className={styles.bg}>
        <PictureImage src={bgFileSrc} />
      </div>}

      {/* MAIN Image */}
      {hasMainFile && <div className={getClassName("mainImage", mainPosition)}>
        <PictureImage positions={mainPosition} src={mainFileSrc} />
      </div>}

      {/* MAIN Lottie */}
      {hasMainLottieFile && <div className={getClassName(`mainLottie`, mainLottiePosition)}>
        <LottieImage id={`ml_${sceneId + index}`} positions={mainLottiePosition} lottieSrc={mainLottieFileSrc}/>
      </div>}

      {/* SUB Image */}
      {hasSubFile && <div className={getClassName(`subImage`, subPosition)}>
        <PictureImage positions={subPosition} src={subFileSrc} />
      </div>}

      {/* Sub Lottie */}
      {hasSubLottieFile && <div className={getClassName(`subLottie`, subLottiePosition)}>
        <LottieImage id={`sl_${sceneId + index}`} positions={subLottiePosition} lottieSrc={subLottieFileSrc}/>
      </div>}

      {/* NOISE */}
      <Noise />
    </div>
  )
}

export default memo(StoryPictures)


/************************************
 * "/pictures/{storyId}/"のディレクトリに、"{targetFileName}.{extType}(例:momotaro_1-01-main.svg)"のファイルが存在を確認
 * @param storyId
 * @param targetFileName
 * @param extType
 * @returns {{fileSrc: string, hasFile: boolean}}
 */
const CheckImage = (storyId, targetFileName = '', extType) => {

  let fileName = ''
  let hasFile = false
  let fileSrc = ''

  // './json/fileList.json' から targetFileName が含まれるファイルリストを取得
  const fileArray = JsonData.imageFileListData.filter(d => d.includes(targetFileName))

  //
  if (fileArray.length > 0) {
    fileName = getFileName(fileArray, extType)
    hasFile = !!fileName
    fileSrc = `/assets/pictures/${storyId}/${fileName}`
  }
  return {hasFile, fileSrc}
}

const getFileName = (fileArray, extType) => {
  let fileName = ""
  ExtList[extType].forEach(ext => {
    if(fileName) return
    const array = fileArray.filter(fileName => fileName.includes(`.${ext}`))
    if(array.length > 0) {
      fileName = array[0]
    }
  })
  return fileName
}

/************************************
 * ファイル名に `___` のあとに、画像の配置を指定してもらっているので、
 * 配置の情報を確認して返す。
 * {sceneId}-{imageType}___{x}-{y}.{ext}
 *
 * @param fileName
 * @returns {string|{x: (string|*), y: (string|*)}}
 */
const CheckPosition = (fileName) => {
  if (!fileName) return ''

  let positions = {x: "50%", y: "50%", "transform": "translate(-50%, -50%)"}
  if(!fileName.includes('___')) return positions
  // [STEP.1] {sceneId}-{imageType}___{x}-{y}.{ext} → {x}-{y}.{ext}
  const step1 = fileName.split('___')[1]
  // [STEP.2] {x}-{y}.{ext} → {x}-{y}
  const step2 = step1.split('.')[0]
  // [FINAL!] {x}-{y} → [{x}, {y}]
  const position = step2.split('-')

  positions = {x: checkUnit(position[0]), y: checkUnit(position[1])}

  // underが指定されている場合は... TODO:説明を追加しよ。
  if(positions.y === 'under') {
    positions.y = 'bottom'
    positions.isUnder = true
  }
  if(positions.y === 'upper') {
    positions.y = 'top'
    positions.isUpper = true
  }

  return positions
}

/************************************
 * {x}-{y}の指定の方法が、
 * StrPositions = [left, center...] or 80 or 80px
 * の3通りがあり、 80の場合に % を付与するための関数
 *
 * @param str
 * @returns {string|*}
 */
const StrPositions = ['left', 'center', 'right', 'top', 'bottom', 'under', 'upper']
const checkUnit = str => { // left | center | top | top | bottom | under | upper | 80 | 80px
  if (!str) return ''
  if (StrPositions.includes(str) || str.includes('px')) {
    return str
  } else {
    return str + '%'
  }
}
