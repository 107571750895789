import {Howl, Howler} from 'howler';
import gsap from "gsap"

const AudioPath = "/assets/audio/"

const BGM_LIST = [
  {id: 'bgm', fileName: 'loopsound'}
]

const SE_LIST = [
  {id: 'button', fileName: 'click'},
  {id: 'nextPage', fileName: 'pagenext'},
  {id: 'menuOpen', fileName: 'menuopen'},
  {id: 'menuClose', fileName: 'menuclose'},
  {id: 'selectStory', fileName: 'storyselect'},
  {id: 'getMedal', fileName: 'medalget'},
  {id: 'cancel', fileName: 'cancel'},
]

const VOLUME = 0.5
const LOCAL_STORAGE_NAME = '_mute'

let instance;

class Audio {
  constructor() {
    if (instance) {
      throw new Error("You can only create one instance!");
    }
    instance = this;

    const muteLS = window.localStorage.getItem(LOCAL_STORAGE_NAME)
    this._muted = (muteLS) ? muteLS === 'true' : false
    this._bgms = {}
    this._soundEffects = {}

    this.currentBgmId = null

    Howler.volume(VOLUME)
  }

  getInstance() {
    return this
  }

  get muted() {
    return this._muted
  }

  get bgms() {
    return this._bgms
  }

  get soundEffects() {
    return this._soundEffects
  }

  load() {
    SE_LIST.forEach(data => {
      const audio = new Howl({
        src: [`${AudioPath}${data.fileName}.mp3`]
      })
      this._soundEffects[data.id] = audio
    })
    BGM_LIST.forEach(data => {
      const audio = new Howl({
        src: [`${AudioPath}${data.fileName}.mp3`],
        loop: true
      })
      this._bgms[data.id] = audio
    })
  }

  toggleMuted = () => {
    if(this._muted) {
      this.unmute()
    } else {
      this.mute()
    }
  }

  mute = () => {
    this._muted = true
    this.volumeControl(VOLUME, 0, () => {
      if(this.currentBgmId) this._bgms[this.currentBgmId].pause()
    })
    window.localStorage.setItem(LOCAL_STORAGE_NAME, 'true')
  }

  unmute = () => {
    this._muted = false
    if(this.currentBgmId) this._bgms[this.currentBgmId].play()
    this.volumeControl(0, VOLUME, () => {
    })
    window.localStorage.setItem(LOCAL_STORAGE_NAME, 'false')
  }

  volumeControl = (from, to, completeFunction) => {
    const data = {vol: from}
    gsap.to(data, {
      vol: to,
      onUpdate: () => {
        Howler.volume(data.vol)
      },
      onComplete: () => {
        if(completeFunction) completeFunction()
      }
    })
  }

  playBGM = (id) => {
    if(this.currentBgmId === id) return
    // mute解除後でも再生するように、変数で保持しておく
    this.currentBgmId = id
    if(this._muted) return
    this._bgms[this.currentBgmId].volume(VOLUME)
    this._bgms[id].play()
  }

  resumeBGM = () => {
    if(!this._bgms[this.currentBgmId]) return
    this._bgms[this.currentBgmId].play()
    this._bgms[this.currentBgmId].fade(0, VOLUME, 500)
  }

  pauseBGM = () => {
    if(!this._bgms[this.currentBgmId]) return
    this._bgms[this.currentBgmId].fade(VOLUME, 0, 500)
    setTimeout(() => {
      this._bgms[this.currentBgmId].pause()
    }, 500)
  }

  stopBGM = () => {
    if(!this._bgms[this.currentBgmId]) return
    this._bgms[this.currentBgmId].fade(VOLUME, 0, 500)
    setTimeout(() => {
      this._bgms[this.currentBgmId].seek(0)
      this._bgms[this.currentBgmId].pause()
      this.currentBgmId = null
    }, 500)
  }

  playSE = (id) => {
    if(this._muted) return
    this._soundEffects[id].play()
  }

  playButton = () => {
    this.playSE("button")
  }
}

const singletonCounter = new Audio()
export default singletonCounter;
