import {GetQueriesBySearch} from "../utils/common";
import {Env} from "./AppConstant";

const _Debug = {
  Top: {
    'killAnimation': {
      value: false
    }
  },
  Story: {
    'showMedalState': {
      value: false
    },
    'killRedirect': {
      value: false
    },
    // 'killRedirectWhenGetMedalId': {
    //   value: false
    // },
    'killButtonFadeIn': {
      value: false
    }
  }
}

export const DebugExplain = {
  'killAnimation': 'トップのアニメーションを切る',
  'showMedalState': 'ストーリーページでメダルの取得状況を表示',
  'killRedirect': 'URL直接入力禁止する機能を切る',
  // 'killRedirectWhenGetMedalId': '取得済みメダルの演出スキップ機能を切る',
  'killButtonFadeIn': 'ボタンのフェードインの演出を切る',
}

const LocalStorageName = '_debug-values'
let instance;

class DebugConstants {
  constructor() {
    if (instance) {
      throw new Error("You can only create one instance!");
    }
    instance = this;
    this.debugValues = this.marge()
  }

  marge() {
    const debugValuesInLocalStorage = localStorage.getItem(LocalStorageName)
    const queries = GetQueriesBySearch(window.location.search)

    const baseDebugObj = (!!debugValuesInLocalStorage) ? JSON.parse(debugValuesInLocalStorage) : _Debug
    const newObj = {}

    Object.keys(baseDebugObj).forEach(pageId => {
      Object.keys(baseDebugObj[pageId]).forEach(key => {
        if(queries) {
          if(!!queries[key]) baseDebugObj[pageId][key] = {value: true}
        }
        if(!_Debug[pageId][key]) {
          delete baseDebugObj[pageId][key]
        }
      })

      newObj[pageId] = (baseDebugObj[pageId]) ? {..._Debug[pageId], ...baseDebugObj[pageId]} : _Debug[pageId]
    })
    return newObj
  }

  getInstance() {
    return this
  }

  getValues(pageId, debugId) {
    if(!Env.isDevelopment) {
      return false
    }
    return this.debugValues[pageId][debugId].value
  }

  setValues(pageId, debugId, boolean) {
    if(typeof boolean === "boolean") {
      this.debugValues[pageId][debugId].value = boolean
      localStorage.setItem(LocalStorageName, JSON.stringify(this.debugValues))
    } else {
      console.warn('値がBooleanではないため、処理不可能')
    }
  }
}

const singletonCounter = Object.freeze(new DebugConstants());
export default singletonCounter;
