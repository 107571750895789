import ReactDOM from 'react-dom';

const modalRootEl = document.getElementById('modal');

const PortalModal = (
  {
    // 表示フラグ
    open = false,
    //
    children
  }) => {

  if (!open) return null;

  return ReactDOM.createPortal(children, modalRootEl);
};

export default PortalModal;
