import styles from "./Noise.module.scss"

function Noise() {

  return <div
    className={styles.noise}
  />
}

export default Noise
